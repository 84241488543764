import React, { createContext, useState } from 'react';
import { getConfig } from '../../Config';

const portalBaseUrl = getConfig().portalBaseUrl;
const DEEP_SUPPORT_USER = 'deep-support';
const POLICE_USER = 'police';
const HOME_OFFICE_USER = 'home-office';
const RETAILER_USER = 'retailer';
const INTERNAL_USER = 'internal';
const ONE_OFF_RETAILER = 'one-off-retailer';

export const AuthContext = createContext();

const AuthProvider = (props) => {
  const [keycloak, setKeycloak] = useState(props.keycloak);

  const getTokenParsed = () => {
    let tokenData = sessionStorage.getItem('tokenData');
    if (tokenData) {
      return JSON.parse(tokenData);
    } else {
      return {};
    }
  };

  const getGroup = () => {
    const tokenParsed = getTokenParsed();
    const group = tokenParsed.groups && tokenParsed.groups[0];
    return group;
  };

  const handleLogin = (forwardUrl) => {
    const redirectUri = portalBaseUrl + forwardUrl;
    props.keycloak.login({ redirectUri });
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    if (keycloak) {
      const redirectUri = portalBaseUrl + '/login';
      keycloak.clearToken();
      await keycloak.logout({ redirectUri });
    }
  };

  const setKeycloakInstance = (kc) => {
    setKeycloak(kc);
  };

  const isAuthenticated = () => {
    return sessionStorage.getItem('authenticated') === 'true';
  };

  const isDeepSupportUser = () => {
    const group = getGroup();
    return [DEEP_SUPPORT_USER].includes(group);
  };

  const isPoliceUser = () => {
    const group = getGroup();
    return [POLICE_USER].includes(group);
  };

  const isHomeOfficeUser = () => {
    const group = getGroup();
    return [HOME_OFFICE_USER].includes(group);
  };

  const isInternalUser = () => {
    const group = getGroup();
    return [INTERNAL_USER].includes(group);
  };

  const isRetailer = () => {
    const group = getGroup();
    return [RETAILER_USER].includes(group);
  };

  const isDeepSupportOrHomeOffice = () => {
    return isDeepSupportUser() || isHomeOfficeUser();
  };
  const isOneOffRetailer = () => {
    const group = getGroup();
    return [ONE_OFF_RETAILER].includes(group);
  };

  const isOneOffUser = () => {
    return (
      isAuthenticated() &&
      !(
        isDeepSupportUser() ||
        isHomeOfficeUser() ||
        isOneOffRetailer() ||
        isRetailer() ||
        isPoliceUser()
      )
    );
  };

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        setKeycloakInstance,
        keycloak,
        isAuthenticated,
        handleLogout,
        isDeepSupportUser,
        isPoliceUser,
        isHomeOfficeUser,
        isOneOffRetailer,
        isDeepSupportOrHomeOffice,
        isOneOffUser,
        isInternalUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
