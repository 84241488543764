import React from 'react';
import { Select } from '../../components/Govuk';
import { noop, getPageRange, isCurrentPage } from './utils';
import './Pagination.css';

const pageSizeOptions = [
  { value: '25', description: '25' },
  { value: '50', description: '50' },
  { value: '75', description: '75' },
  { value: '100', description: '100' },
];

const Summary = (props) => {
  const { page, pageSize, totalResults } = props;
  const lastItemRange = page * pageSize;
  const firstItemPosition = lastItemRange + 1 - pageSize;
  const lastItemPosition =
    lastItemRange > totalResults ? totalResults : lastItemRange;
  return (
    <div className="pagination__summary">
      Showing {firstItemPosition} - {lastItemPosition} of {totalResults} results
    </div>
  );
};

const PreviousPage = (props) => {
  const { page } = props;
  const { onPrevPageChange } = props;
  const mute = page === 1;
  return (
    <li id="previous-page" className="pagination__item">
      <a
        className={`pagination__link ${mute ? 'disabled-link' : ''}`}
        href="!#"
        onClick={(e) => (mute ? noop(e) : onPrevPageChange(e))}
        aria-disabled={mute ? true : false}
        aria-label="Previous page"
      >
        <span aria-hidden="true" role="presentation">
          &laquo;
        </span>{' '}
        Previous
      </a>
    </li>
  );
};

const NextPage = (props) => {
  const { page, lastPage } = props;
  const { onNextPageChange } = props;
  const mute = page === lastPage;
  return (
    <li id="next-page" className="pagination__item">
      <a
        className={`pagination__link ${mute ? 'disabled-link' : ''}`}
        href="!#"
        onClick={(e) => (mute ? noop(e) : onNextPageChange(e))}
        aria-disabled={mute ? true : false}
        aria-label="Next page"
      >
        Next{' '}
        <span aria-hidden="true" role="presentation">
          &raquo;
        </span>
      </a>
    </li>
  );
};

const Pages = (props) => {
  const { lastPage, page } = props;
  const { onPageChange } = props;
  const pageRange = getPageRange(page, lastPage);
  return pageRange.map((pageNum) => (
    <li className="pagination__item" key={`page-${pageNum}`}>
      <a
        data-test={`page-${pageNum}`}
        className={`pagination__link ${isCurrentPage(page, pageNum)}`}
        href="!#"
        onClick={(e) => onPageChange(e, pageNum)}
        aria-label={`Page ${pageNum}`}
      >
        {pageNum}
      </a>
    </li>
  ));
};

const Pagination = (props) => {
  const { page, pageSize, totalResults } = props;
  const { onPageSizeChange, onPrevPageChange, onPageChange, onNextPageChange } =
    props;

  const lastPage = Math.ceil(totalResults / pageSize);

  return (
    <nav role="navigation" aria-label="Pagination">
      <Select
        label="Results per page"
        options={pageSizeOptions}
        name="select-page-result-size"
        onChange={onPageSizeChange}
        selected={pageSize}
        data-test="select-result-size"
      />
      <Summary page={page} pageSize={pageSize} totalResults={totalResults} />
      <ul className="pagination">
        <PreviousPage page={page} onPrevPageChange={onPrevPageChange} />
        <Pages page={page} lastPage={lastPage} onPageChange={onPageChange} />
        <NextPage
          page={page}
          lastPage={lastPage}
          onNextPageChange={onNextPageChange}
        />
      </ul>
    </nav>
  );
};

export default Pagination;
