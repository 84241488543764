import React from 'react';
import { Link } from 'react-router-dom';

const Btn = ({ content, disabled }) => {
  return (
    <button
      className="govuk-button"
      data-module="govuk-button"
      data-test={`button-${content}`}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export const Button = ({
  content,
  isLink,
  url,
  action,
  id,
  disabled = false,
}) => {
  function getButton() {
    if (isLink) {
      return (
        <Link to={url}>
          <Btn content={content} disabled={disabled} />
        </Link>
      );
    } else if (!isLink && !action) {
      return <Btn content={content} disabled={disabled} />;
    } else if (action) {
      return (
        <button
          id={id}
          onClick={action}
          type="submit"
          data-prevent-double-click="true"
          className="govuk-button"
          data-module="govuk-button"
          data-test={`button-${content}`}
          disabled={disabled}
        >
          {content}
        </button>
      );
    }
  }

  return getButton();
};

Button.defaultProps = {
  content: 'Continue',
  isLink: false,
  id: 'data-govuk-button',
};
