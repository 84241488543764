import React from 'react';
import { Link } from 'react-router-dom';

export const OneOffSarRegistrationNotAllowed = () => {
  return (
    <div className="govuk-width-container">
      <main
        className="govuk-main-wrapper "
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">Operation not allowed</h1>
            <p className="govuk-body">
              As a regular reporter, because you are already registered with the
              service, you can only submit a one-off suspicious activity report
              by logging into your account and using the link provided on the
              home page.
            </p>
            <Link to={'/login'}>Return to the home page</Link>
          </div>
        </div>
      </main>
    </div>
  );
};
