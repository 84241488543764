import React from 'react';
import { Field } from 'formik';

export const Address = ({ question, name, subText }) => {
  return (
    <>
      <fieldset className="govuk-fieldset">
        <legend className="govuk-fieldset__legend govuk-fieldset__legend--s">
          <h2 className="govuk-fieldset__heading">{question}</h2>
        </legend>
        <div id="address-hint" className="govuk-hint">
          <p>{subText}</p>
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.addressLine1`}>
            Building and street
            <span className="govuk-visually-hidden">line 1 of 2</span>
          </label>
          <Field
            className="govuk-input"
            id={`${name}.addressLine1`}
            name={`${name}.addressLine1`}
            data-test={`${name}.addressLine1`}
            type="text"
            inputMode="text"
          />
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.addressLine2`}>
            <span className="govuk-visually-hidden">
              Building and street line 2 of 2
            </span>
          </label>
          <Field
            className="govuk-input"
            id={`${name}.addressLine2`}
            name={`${name}.addressLine2`}
            data-test={`${name}.addressLine2`}
            type="text"
            inputMode="text"
          />
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.city`}>
            Town or city
          </label>
          <Field
            className="govuk-input govuk-!-width-two-thirds"
            id={`${name}.city`}
            name={`${name}.city`}
            data-test={`${name}.city`}
            type="text"
            aria-describedby={`${name}.city`}
            inputMode="text"
            aria-labelledby={`${name}.city`}
          />
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.county`}>
            County
          </label>
          <Field
            className="govuk-input govuk-!-width-two-thirds"
            id={`${name}.county`}
            name={`${name}.county`}
            data-test={`${name}.county`}
            type="text"
            aria-describedby={`${name}.county`}
            inputMode="text"
            aria-labelledby={`${name}.county`}
          />
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.postcode`}>
            Postcode
          </label>
          <Field
            className="govuk-input govuk-input--width-10"
            id={`${name}.postcode`}
            name={`${name}.postcode`}
            data-test={`${name}.postcode`}
            type="text"
            aria-describedby={`${name}.postcode`}
            inputMode="text"
            aria-labelledby={`${name}.postcode`}
          />
        </div>
        <div className="govuk-form-group">
          <label className="govuk-label" htmlFor={`${name}.country`}>
            Country
          </label>
          <Field
            className="govuk-input govuk-!-width-two-thirds"
            id={`${name}.country`}
            name={`${name}.country`}
            data-test={`${name}.country`}
            type="text"
            aria-describedby={`${name}.country`}
            inputMode="text"
            aria-labelledby={`${name}.country`}
          />
        </div>
      </fieldset>
    </>
  );
};
