import React from 'react';

export const Contact = () => {
  return (
    <>
      <h2 className="govuk-heading-l" data-test="contact-header">
        Contact the Chemical Reporting Team
      </h2>
      <p className="govuk-body" data-test="contact-body">
        For questions about what to report and how to submit a report, email{' '}
        <a
          href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
          data-test="contact-crt-email"
        >
          ChemicalReportingTeam@homeoffice.gov.uk
        </a>
        <br />
        <br />
        For technical questions, email{' '}
        <a
          href="mailto:DSAChemicalReporting@homeoffice.gov.uk"
          data-test="contact-dsa-email"
        >
          DSAChemicalReporting@homeoffice.gov.uk
        </a>
      </p>
    </>
  );
};
