import React from 'react';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../PreviousUploads.css';
import { PreviousUploadsTableRow } from './PreviousUploadsTableRow';

export const PreviousUploadsTable = ({
  sortDateByTimestamp,
  dateSortAsc,
  sarReports,
  onReportDetailClick,
}) => {
  return (
    <>
      <table className="govuk-table" data-test="previous-upload-table">
        <thead>
          <tr className="govuk-table__row">
            <th
              scope="col"
              className="govuk-table__header"
              onClick={sortDateByTimestamp}
              data-test="table-header-timestamp"
            >
              Date / time
              {dateSortAsc ? (
                <FontAwesomeIcon id={'data-button-asc-date'} icon={faSortUp} />
              ) : (
                <FontAwesomeIcon
                  id={'data-button-desc-date'}
                  icon={faSortDown}
                />
              )}
            </th>
            <th
              scope="col"
              className="govuk-table__header"
              data-test="table-header-retailer"
              style={{ cursor: 'default' }}
            >
              Retailer
            </th>
            <th
              scope="col"
              className="govuk-table__header"
              data-test="table-header-filename"
              style={{ cursor: 'default' }}
            >
              File name
            </th>
            <th
              scope="col"
              className="govuk-table__header"
              data-test="table-header-id"
              style={{ cursor: 'default' }}
            >
              ID
            </th>
            <th
              scope="col"
              className="govuk-table__header"
              data-test="table-header-status"
              style={{ cursor: 'default' }}
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="govuk-table__body">
          {sarReports.map((report) => (
            <PreviousUploadsTableRow
              report={report}
              onReportDetailClick={onReportDetailClick}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
