import React, { useEffect } from 'react';
import { StartButton } from '../../../components/Govuk';

export const BeforeStart = () => {
  useEffect(() => {
    document.title = 'Before you start - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">Before you start</h1>
            <p className="govuk-body">
              Use the one-off report to share information about the suspicious
              incident involving explosives precursors and poisons.
            </p>
            <p className="govuk-body">
              If you cannot answer a question, you can leave it blank unless
              otherwise told.
            </p>
            <p className="govuk-body">
              If you haven’t submitted your report within 24 hours of first
              opening this page, you will need to start again.
            </p>
            <p className="govuk-body">
              The form should take between 5 and 15 minutes to complete,
              depending on your familiarity with the form and the amount of
              information you can provide.
            </p>
            <p className="govuk-body">
              You cannot save this form and come back to it, so have everything
              you need before you start.
            </p>
            <p className="govuk-body">
              Have the following ready if it is known and relevant to the
              activity you are reporting:
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>details about the suspicious person</li>
              <li>details about the product(s) and chemical(s)</li>
              <li>details about the payment</li>
              <li>your crime reference number, if you have one</li>
            </ul>
            <StartButton
              url={'/sar/provider-information'}
              dataTest="start-one-off-sar"
            />
            <h2 className="govuk-heading-m">Storing evidence</h2>
            <p className="govuk-body">
              If possible, keep the following evidence for six months:
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>receipts</li>
              <li>ID details</li>
              <li>CCTV records</li>
              <li>documentation handled by the customer</li>
            </ul>
            <h2 className="govuk-heading-m">Support</h2>
            <p className="govuk-body">
              For any questions, contact the Chemical Reporting Team at{' '}
              <a
                className="govuk-link"
                href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
              >
                ChemicalReportingTeam@homeoffice.gov.uk
              </a>
            </p>
            <p className="govuk-body">
              The inbox is monitored by the team Monday to Friday 9am to 5pm. It
              is not monitored on bank holidays.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};
