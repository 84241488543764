import React from 'react';

const AccessibilityStatement = () => {
  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l">
            Accessibility statement for the Report suspicious chemical activity
            service
          </h1>
          <h2 className="govuk-heading-m">
            This accessibility statement applies to the Report suspicious
            chemical activity service offered by the Home Office.
          </h2>
          <p className="govuk-body">
            This website is run by the Home Office. We offer an online service
            for retailers and members of the public to report suspicious
            activity involving explosives precursors and poisons. This includes
            suspicious purchases, losses and thefts. We want as many people as
            possible to be able to use this website. For example, that means you
            should be able to:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>Change colours, contrast levels and fonts.</li>
              <li>
                Zoom in up to 200% without the text spilling off the screen.
              </li>
              <li>Navigate most of the website using just a keyboard.</li>
              <li>
                Navigate most of the website using speech recognition software.
              </li>
              <li>
                Listen to most of the website using a screen reader (including
                the most recent versions of JAWS, NVDA and VoiceOver).
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            We’ve also made the website text as simple as possible to
            understand.{' '}
            <a
              href="https://mcmw.abilitynet.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AbilityNet
            </a>{' '}
            has advice on making your device easier to use if you have a
            disability.
          </p>
          <h2 className="govuk-heading-m">How accessible this website is</h2>
          <p className="govuk-body">
            We know some parts of this website are not fully accessible. You can
            see a full list of any issues we currently know about in the
            Non-accessible content section of this statement.
          </p>
          <h2 className="govuk-heading-m">Feedback and contact information</h2>
          <p className="govuk-body">
            If you need information on this website in a different format like
            accessible PDF, large print, easy read, audio recording or braille:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                Email{' '}
                <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
                  ChemicalReportingTeam@homeoffice.gov.uk
                </a>
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            We’ll consider your request and get back to you within seven days.
          </p>
          <h2 className="govuk-heading-m">
            Reporting accessibility problems with this website
          </h2>
          <p className="govuk-body">
            We’re always looking to improve the accessibility of this website.
            If you find any problems not listed on this page or think we’re not
            meeting accessibility requirements, contact the Chemical Reporting
            Team:{' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            .
          </p>
          <p className="govuk-body">
            Please let us know:
            <ul className="govuk-list govuk-list--bullet">
              <li>the URL where you found the problem</li>
              <li>
                what the problem is - for example, a wording which is difficult
                to understand
              </li>
              <li>
                what computer and software you’re using, and whether you use any
                assistive technology
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            <a
              href="http://www.w3.org/WAI/users/inaccessible"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read tips on contacting organisation about inaccessible websites.
            </a>
          </p>
          <h2 className="govuk-heading-m">Enforcement procedure</h2>
          <p className="govuk-body">
            The Equality and Human Rights Commission (EHRC) is responsible for
            enforcing the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018 (the
            ‘accessibility regulations’). If you’re not happy with how we
            respond to your complaint,{' '}
            <a
              href="https://www.equalityadvisoryservice.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact the Equality Advisory and Support Service (EASS).
            </a>
          </p>
          <p className="govuk-body">
            If you are in Northern Ireland and are not happy with how we respond
            to your complaint you can contact the{' '}
            <a
              href="https://www.equalityni.org/Home"
              target="_blank"
              rel="noopener noreferrer"
            >
              Equalities Commission for Northern Ireland
            </a>{' '}
            who are responsible for enforcing the Public Sector Bodies (Websites
            and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the
            ‘accessibility regulations’) in Northern Ireland.
          </p>
          <h2 className="govuk-heading-m">
            Technical information about this website’s accessibility
          </h2>
          <p className="govuk-body">
            The Home Office is committed to making its website accessible, in
            accordance with the Public Sector Bodies (Websites and Mobile
            Applications) (No. 2) Accessibility Regulations 2018.
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>Compliance status</li>
            </ul>
          </p>
          <p className="govuk-body">
            This website is partially compliant with the{' '}
            <a
              href="https://www.w3.org/TR/WCAG21/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web Content Accessibility Guidelines version 2.1
            </a>{' '}
            AA standard due to the non-compliances listed below.
          </p>
          <h3 className="govuk-heading-m">Non-accessible content</h3>
          <p className="govuk-body">
            The content listed below is non-accessible for the following
            reasons.
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                <span className="govuk-!-font-weight-bold">Unusual words</span>{' '}
                - Some of our questions on the one-off form are aimed at
                retailers of chemical products. For this reason people might
                find some words difficult to understand, such as ‘molality’.
                However, none of these questions are mandatory. We're currently
                changing some questions based on what our users have told us.
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            If you find an issue that we have yet to identify, please contact us
            using one of the routes described in the ‘Reporting accessibility
            problems with this website’ section of this statement.
          </p>
          <h3 className="govuk-heading-m">Disproportionate burden</h3>
          <p className="govuk-body">
            At this time, we have not made any disproportionate burden claims.
          </p>
          <h3 className="govuk-heading-m">
            Content that’s not within the scope of the accessibility regulations
          </h3>
          <p className="govuk-body">
            At this time, we have not identified any content that is not within
            scope of the accessibility regulations.
          </p>
          <h3 className="govuk-heading-m">
            Preparation of this accessibility statement
          </h3>
          <p className="govuk-body">
            This statement was prepared on 13 July 2021. It was last reviewed on
            13 July 2021.
          </p>
          <p className="govuk-body">
            This website was last tested on 25 June 2021. Testing was carried
            out internally by the Home Office.
          </p>
          <p className="govuk-body">
            We tested all the screens of the service.
          </p>
        </div>
      </main>
    </>
  );
};

export { AccessibilityStatement };
