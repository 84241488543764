import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { InputBox, Enum } from '../components';
import { NUMBER_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const createQuestionTitles = (index) => {
  let products = [];
  for (let i = 0; i < index; i++) {
    products.push({
      product_unit_price_paid: 'How much did this cost?',
    });
  }
  return {
    products,
  };
};

const ProductInformationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      product_unit_price_paid: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    })
  ),
});

const formikWrapper = withFormik({
  validationSchema: ProductInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Cost',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'is-discount';
    if (
      values.editMode &&
      values.sections.has(`product-review.${values.productIndex}`)
    ) {
      next = 'review-answers';
    }
    values.sections.add(`cost.${values.productIndex}`);
    formikBag.props.updateValues(values, next);
  },
});

const Cost = ({ handleSubmit, values, setFieldValue }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Cost',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Cost - Report Suspicious Chemical Activity';
  }, []);

  const index = values.productIndex;
  const productNumber = index + 1;
  const questionTitles = createQuestionTitles(productNumber);
  const exists = values.products.filter((product) => product.id === index);
  if (exists.length === 0) {
    history.goBack();
    return <></>;
  }

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`cost.${index}`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList questionTitles={questionTitles} />
            <span className="govuk-caption-l">Product {productNumber}</span>
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  isNumber={true}
                  name={`products.${index}.product_unit_price_paid`}
                  size="5"
                  subText="Enter the price of the item, including VAT."
                  hintId="cost-hint"
                  questionLarge={
                    questionTitles.products[index].product_unit_price_paid
                  }
                />
                <Enum
                  question="Currency"
                  enumValues={[
                    { value: 'GBP' },
                    { value: 'EUR' },
                    { value: 'USD' },
                    { value: 'Other' },
                  ]}
                  name={`products.${index}.currency`}
                  direction="col"
                  values={values}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const CostWithFormik = formikWrapper(Cost);

export { CostWithFormik as Cost };
