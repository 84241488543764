import React, { useState, useEffect } from 'react';
import { Button, DatePicker } from '../../components/Govuk';
import { fromDateAsIso, toDateAsIso } from './utils';

const PreviousUploadsFilter = (props) => {
  const [dateFrom, setDateFrom] = useState({});
  const [dateTo, setDateTo] = useState({});
  const [filterDisabled, setFilterDisabled] = useState(true);

  const updateDateFrom = (key, value) => {
    updateDate(key, value, setDateFrom);
  };

  const updateDateTo = (key, value) => {
    updateDate(key, value, setDateTo);
  };

  const updateDate = (key, value, setDate) => {
    setDate((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const isDateValueSet = () => {
    return checkInvalidDate(dateFrom) || checkInvalidDate(dateTo);
  };

  const checkInvalidDate = (date) => {
    if (Object.entries(date).length >= 3) {
      return !(date.day === '' || date.month === '' || date.year === '');
    } else {
      return false;
    }
  };

  const performFilter = () => {
    if (isDateValueSet()) {
      const formattedDateFrom = checkInvalidDate(dateFrom)
        ? fromDateAsIso(dateFrom)
        : null;
      const formattedDateTo = checkInvalidDate(dateTo)
        ? toDateAsIso(dateTo)
        : null;
      props.getFilteredReports(formattedDateFrom, formattedDateTo);
    }
  };

  useEffect(() => {
    setFilterDisabled(!isDateValueSet());
  }, [dateFrom, dateTo]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <h2 className="govuk-heading-m" data-test="filters=header">
          Filters
        </h2>
        <div style={{ width: '50%', display: 'inline-block' }}>
          <DatePicker
            id="previous-uploads-filter-date-from"
            setDate={updateDateFrom}
            title={'From'}
            formClass={'govuk-form-group'}
          />
        </div>
        <div style={{ width: '50%', display: 'inline-block' }}>
          <DatePicker
            id="previous-uploads-filter-date-to"
            setDate={updateDateTo}
            title={'To'}
            formClass={'govuk-form-group'}
          />
        </div>
      </div>
      <Button
        disabled={filterDisabled}
        action={performFilter}
        content="Filter"
      />
    </>
  );
};

export default PreviousUploadsFilter;
