import React from 'react';
import { Field } from 'formik';

export const CheckList = ({
  listValues = [],
  name,
  questionLarge,
  subText,
  hintId,
}) => {
  const getCheckBoxEntry = (label, key) => {
    return (
      <div className="govuk-checkboxes__item" key={key}>
        <Field
          className="govuk-checkboxes__input"
          id={`${name}-${key}`}
          name={`${name}`}
          data-test={`${name}-${listValues[key]}`}
          type="checkbox"
          value={listValues[key]}
        />
        <label
          className="govuk-label govuk-checkboxes__label"
          htmlFor={`${name}-${key}`}
        >
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className="govuk-form-group">
      <fieldset className="govuk-fieldset">
        {questionLarge && (
          <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
            <h1 className="govuk-fieldset__heading">{questionLarge}</h1>
          </legend>
        )}
        {subText && (
          <div id={hintId} className="govuk-hint">
            {subText}
          </div>
        )}
        <div className="govuk-checkboxes">
          {listValues.map((value, key) => getCheckBoxEntry(value, key))}
        </div>
      </fieldset>
    </div>
  );
};
