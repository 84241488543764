import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { Enum } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  TRANSACTION_TYPE_OTHER,
  TRANSACTION_TYPE_REQUIRED,
} from '../validation-utils';

const MethodSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    type: Yup.string().required(TRANSACTION_TYPE_REQUIRED),
  }),
});

const formikWrapper = withFormik({
  validationSchema: MethodSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validate: (values) => {
    const errors = {};
    if (values.transaction.type === 'Other') {
      if (values.transaction.other_transaction.length === 0) {
        errors['transaction'] = {};
        errors['transaction']['other_transaction'] = TRANSACTION_TYPE_OTHER;
      }
    }
    return errors;
  },

  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Method',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'suspicion';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('transaction-information');
    formikBag.props.updateValues(values, next);
  },
});

const Method = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Transaction Method',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Channel - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`transaction-information`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-l">About the activity</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[
                    { value: 'In store' },
                    { value: 'Online' },
                    { value: 'By phone' },
                    { value: 'Through email' },
                    {
                      value: 'Other',
                      conditionalName: 'transaction.other_transaction',
                      conditionalLabel: 'Where the activity took place',
                    },
                    { value: 'I don`t know' },
                  ]}
                  name="transaction.type"
                  direction="col"
                  questionLarge="Where did the suspicious activity happen?"
                  values={values}
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const MethodWithFormik = formikWrapper(Method);

export { MethodWithFormik as Method };
