const ProviderInfoInitialValues = {
  provider: {
    person_fullname: '',
    email: '',
    telephone: '',
    organisation_name: '',
    epp_license_number: '',
    contact_method: '',
    reporting_for_org: '',
  },
};

const AboutIncidentInitialValues = {
  about_incident: {
    report_type: '',
    reason_suspicious: '',
  },
};

const AboutPersonInitialValues = {
  about_person: {
    buyer: {
      fullname: '',
      email: '',
      telephone: '',
      dob: {
        day: '',
        month: '',
        year: '',
      },
      billing: '',
      delivery: '',
      description: '',
    },
    crime_details: {
      crime_reference: '',
    },
  },
};

const productValues = {
  id: 0,
  product_name: '',
  product_description: '',
  currency: 'GBP',
};

export const defaultProductValues = productValues;

const ProductInfoInitialValues = {
  products: [productValues],
};

const TransactionInfoInitialValues = {
  transaction: {
    type: '',
    date: {
      day: '',
      month: '',
      year: '',
    },
    time: '',
    other_transaction: '',
    payment_method: '',
    other_payment: '',
    payment_description: '',
    further_info: '',
  },
};

export const OneOffSarInitialValues = {
  ...ProviderInfoInitialValues,
  ...AboutIncidentInitialValues,
  ...AboutPersonInitialValues,
  ...ProductInfoInitialValues,
  ...TransactionInfoInitialValues,
  productIndex: 0,
  sections: new Set(),
};
