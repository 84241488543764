import React, { useEffect, useContext } from 'react';
import { OneOffAuthContext } from '../../components/oneOffAuth/OneOffAuthContext';

export const OneOffSarsAuth = (props) => {
  const { validateUser } = useContext(OneOffAuthContext);
  const { location, history } = props;

  useEffect(() => {
    validateUser(location.hash)
      .then(() => history.push('/sar/before-starting'))
      .catch(() => history.push('/one-off-sars-error'));
  }, [history, location.hash, validateUser]);

  return <></>;
};
