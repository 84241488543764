import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getConfig } from '../../Config';
import './PreviousUploads.css';
import PreviousUploadsFilter from './PreviousUploadsFilter';
import { LoadingSpinner } from '../../components/Govuk';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { PreviousUploadsTable } from './PreviousUploadsTable/PreviousUploadsTable';
import Pagination from './Pagination';
import { get } from 'lodash';

const PreviousUploads = (props) => {
  const [sarReports, setSarReports] = useState([]);
  const [dateSortAsc, setDateSortAsc] = useState(false);
  const [loadingResults, setLoadingResults] = useState(true);
  const [totalResults, setTotalResults] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const sarsReportsEndpoint = getConfig().apiBaseUrl + '/sars-reports';
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Previous uploads Page',
      action: 'Loaded',
    });
  }, []);

  useEffect(() => {
    if (loadingResults) {
      getPagedSarsReports();
    }
  }, [loadingResults]);

  const getFilteredSarsReports = (dateFrom, dateTo) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setPage(1);
    setDateSortAsc(false);
    setPageSize(100);

    setLoadingResults(true);
  };

  const getPagedSarsReports = () => {
    const params = {
      page,
      pageSize,
      dateSortAsc,
      dateFrom,
      dateTo,
    };

    axios
      .get(sarsReportsEndpoint, { params })
      .then((response) => {
        const tmpReports = [];
        const responseHits = get(response, 'data.hits.hits', []);
        responseHits.map((report) => tmpReports.push(report.sourceAsMap));
        setSarReports(tmpReports);
        setLoadingResults(false);
        setTotalResults(response.data.hits.totalHits.value);
      })
      .catch(() => {
        props.history.push(`/sar-error`);
      });
  };

  const onPageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
    setDateSortAsc(false);
    setLoadingResults(true);
  };

  const onPageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
    setLoadingResults(true);
  };

  const onPrevPageChange = (e) => {
    e.preventDefault();
    const previousPage = page - 1;
    setPage(previousPage);
    setLoadingResults(true);
  };

  const onNextPageChange = (e) => {
    e.preventDefault();
    const nextPage = page + 1;
    setPage(nextPage);
    setLoadingResults(true);
  };

  const sortDateByTimestamp = () => {
    if (dateSortAsc) {
      setDateSortAsc(false);
    } else {
      setDateSortAsc(true);
    }
    setLoadingResults(true);
  };

  function onReportDetailClick(reportDetail) {
    props.history.push(`/sar-details/${reportDetail.source_id}`);
  }

  return (
    <main
      style={{ width: '100%' }}
      className="govuk-main-wrapper"
      id="content"
      role="main"
    >
      <h1 className="govuk-heading-l" data-test="previous-uploads-title">
        Previous uploads
      </h1>
      <p className="govuk-body" data-test="previous-uploads-title-subtext">
        Reports you have sent or uploaded.
      </p>
      <PreviousUploadsFilter getFilteredReports={getFilteredSarsReports} />
      {!loadingResults ? (
        <>
          {sarReports.length ? (
            <PreviousUploadsTable
              sortDateByTimestamp={sortDateByTimestamp}
              dateSortAsc={dateSortAsc}
              sarReports={sarReports}
              onReportDetailClick={onReportDetailClick}
            />
          ) : (
            <>
              <div className="no-results govuk-!-font-size-19">
                <p className="govuk-body govuk-!-font-weight-bold">
                  There are no matching results.
                </p>
              </div>
            </>
          )}
          <Pagination
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
            onPageSizeChange={onPageSizeChange}
            onPrevPageChange={onPrevPageChange}
            onPageChange={onPageChange}
            onNextPageChange={onNextPageChange}
          />
        </>
      ) : (
        <LoadingSpinner data-test="spinner" alt="Loading results spinner" />
      )}
    </main>
  );
};

const PreviousUploadsWithAuth = withAuthorisation({
  forwardUrl: '/previous-uploads',
})(PreviousUploads);

export { PreviousUploadsWithAuth as PreviousUploads };
