import React from 'react';
import { Link } from 'react-router-dom';

const FeedbackBanner = () => {
  return (
    <div className="govuk-phase-banner govuk-width-container">
      <p className="govuk-phase-banner__content">
        <strong className="govuk-tag govuk-phase-banner__content__tag">
          beta
        </strong>
        <span className="govuk-phase-banner__text">
          This is a new service – your{' '}
          <Link
            className="govuk-link"
            to="/feedback"
            data-test="feedback-link"
            target="_blank"
          >
            feedback
          </Link>{' '}
          will help us to improve it.
        </span>
      </p>
    </div>
  );
};

export { FeedbackBanner };
