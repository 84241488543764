import React from 'react';
import { Link } from 'react-router-dom';

export const Breadcrumb = ({ trail }) => {
  return (
    <>
      <div className="govuk-breadcrumbs" style={{ marginBottom: '0px' }}>
        <ol className="govuk-breadcrumbs__list">
          {trail.map((item, index) => {
            if (index !== trail.length - 1) {
              return (
                <li
                  key={index}
                  className={
                    'govuk-breadcrumbs__list-item ' +
                    (index === trail.length - 1 ? 'area-current' : '')
                  }
                >
                  <Link key={index} to={item.currentPage}>
                    <span
                      className="govuk-breadcrumbs__link"
                      data-test={`breadcrumb-link-${item.text}`}
                    >
                      {item.text}
                    </span>
                  </Link>
                </li>
              );
            }
          })}
        </ol>
      </div>
    </>
  );
};
