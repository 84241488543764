import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox } from '../components';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Third Party Vendor',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'channel-ref';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('vendor-name');
    formikBag.props.updateValues(values, next);
  },
});

const ThirdPartyVendor = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Transaction Third Party Vendor',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'Reference name - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`vendor-name`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-l">Transaction details</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  name="transaction.third_party_vendor"
                  size="20"
                  questionLarge="What is the name of the third party channel?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ThirdPartyVendorWithFormik = formikWrapper(ThirdPartyVendor);

export { ThirdPartyVendorWithFormik as ThirdPartyVendor };
