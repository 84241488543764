import axios from 'axios';
import { getConfig } from '../../Config';

const sarsReportsEndpoint = getConfig().apiBaseUrl;

export const getRetailers = () => {
  return axios.get(`${sarsReportsEndpoint}/ref-data/retailers`);
};

export const postFile = (formData, config) => {
  return axios.post(
    `${sarsReportsEndpoint}/sars-reports-file`,
    formData,
    config
  );
};

export const getSchema = (type) => {
  return axios({
    url: `${sarsReportsEndpoint}/schema/${type}/_download`,
    method: 'GET',
    responseType: 'blob',
  });
};
