import React from 'react';
import { Link } from 'react-router-dom';

export const StartButton = (props) => {
  const { url, content, id, dataTest } = props;
  return (
    <Link
      to={url}
      role="button"
      draggable={false}
      className="govuk-button app-button--inverted govuk-!-margin-top-6 govuk-!-margin-bottom-6 govuk-button--start"
      id={id}
      data-module="govuk-button"
      data-test={dataTest}
    >
      {content}
      <svg
        className="govuk-button__start-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="17.5"
        height="19"
        viewBox="0 0 33 40"
        role="presentation"
        focusable="false"
      >
        <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
      </svg>
    </Link>
  );
};

StartButton.defaultProps = {
  content: 'Start Now',
  id: 'data-start-button',
};
