import React, { useEffect } from 'react';
import { Form, useFormikContext, withFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  ORGANISATION_ERROR_NAME_MESSAGE,
  ORGANISATION_ERROR_MESSAGE,
} from '../validation-utils';
import { ErrorList } from '../components/ErrorList';
import { Enum } from '../components';
import * as Yup from 'yup';

export const REPORTING_FOR_PROVIDER =
  'Yes, the product(s) are sold by the organisation I work for.';
export const I_OWN_PRODUCTS = 'No, I own the product(s).';
export const WITNESS_ONLY =
  'No, I saw the suspicious activity but have no relation to the product(s) involved.';

const OrganisationSchema = Yup.object().shape({
  provider: Yup.object().shape({
    reporting_for_org: Yup.string().required(ORGANISATION_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: OrganisationSchema,
  validate: (values) => {
    const errors = {};
    if (values.provider.reporting_for_org === REPORTING_FOR_PROVIDER) {
      if (values.provider.organisation_name.length === 0) {
        errors['provider'] = {};
        errors['provider']['organisation_name'] =
          ORGANISATION_ERROR_NAME_MESSAGE;
      }
    }
    return errors;
  },
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the organisation',
      action: 'Completed',
    });
    delete values.matomo;
    let next = '';
    if (values.about_incident.report_type === 'A theft') {
      next = 'crime-reference';
    } else {
      next = 'transaction-date';
    }
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('organisation');
    formikBag.props.updateValues(values, next);
  },
});

const Organisation = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  formik.values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'About the organisation',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title =
      'About the organisation - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`organisation`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <Form autoComplete="on">
              <fieldset className="govuk-fieldset">
                <div className="govuk-grid-column">
                  <Enum
                    enumValues={[
                      {
                        value: REPORTING_FOR_PROVIDER,
                        optionHint:
                          'For example, an owner of a convenience store, shop floor employee or sales associate for a fertiliser supplier.',
                        conditionalName: 'provider.organisation_name',
                        conditionalLabel:
                          'Name of the organisation you work for.',
                      },

                      {
                        value: I_OWN_PRODUCTS,
                        optionHint: 'For example, EPP license holders.',
                        conditionalName: 'provider.epp_license_number',
                        conditionalLabel:
                          'If easily accessible, provide your EPP license number (if you have one).',
                      },
                      {
                        value: WITNESS_ONLY,
                        optionHint: 'For example, a member of the public.',
                      },
                    ]}
                    name="provider.reporting_for_org"
                    direction="col"
                    questionLarge="Are you reporting on behalf of an organisation?"
                    values={values}
                  />
                </div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label="continue"
                >
                  Continue
                </button>
              </fieldset>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const OrganisationWithFormik = formikWrapper(Organisation);

export { OrganisationWithFormik as Organisation };
