import styled, { css } from 'styled-components';

const errorStyle = css`
  padding-left: 15px;
  border-left: 5px solid #d4351c;
`;

export const FormGroup = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  ${({ isValid = true }) => (isValid ? null : errorStyle)}
`;

FormGroup.displayName = 'FormGroup';
