import axios from 'axios';
import { getConfig } from '../../Config';

const portalPublicEndpoint = getConfig().portalPublicUrl;

export const generateUser = (email, retailer) => {
  return axios.post(`${portalPublicEndpoint}/user`, {
    email,
    retailer,
  });
};
