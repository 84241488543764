import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { FileUpload } from './containers/FileUpload';
import { FurtherInformation } from './containers/FurtherInformation';
import { Home } from './containers/Home';
import { Login } from './containers/Login';
import { PrecursorListPage } from './containers/PrecursorList';
import { PreviousUploads } from './containers/PreviousUploads';
import { SarDetails } from './containers/SarDetails';
import { Success } from './containers/Success';
import {
  OneOffSarRegistration,
  OneOffSarRegistrationComplete,
} from './containers/OneOffSarRegistration';
import GenericError from './containers/Errors/GenericError';
import NotFoundError from './containers/Errors/NotFound';
import SomethingWentWrong from './containers/Errors/SomethingWentWrong';
import OneOffSarsError from './containers/Errors/OneOffSarsError';
import SarError from './containers/Errors/SarError';
import Unauthorised from './containers/Errors/Unauthorised';
import { OneOffSarsAuth } from './containers/OneOffSars/auth';
import { OneOffSars } from './containers/OneOffSars';
import { PageTitle } from './containers/PageTitle';

import './App.css';
import { Help } from './containers/Help/Help';
import { AccessibilityStatement } from './containers/AccessibilityStatement/AccessibilityStatement';
import { PrivacyPolicy } from './containers/PrivacyPolicy/PrivacyPolicy';
import { Cookies } from './containers/Cookies/Cookies';
import { ChangeCookies } from './containers/ChangeCookies/ChangeCookies';
import { Feedback } from './containers/Feedback/Feedback';
import { FeedbackConfirmation } from './containers/Feedback/FeedbackConfirmation';
import { OneOffSarRegistrationNotAllowed } from './containers/OneOffSarRegistration/OneOffSarRegistrationNotAllowed';

export const Routes = (props) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/login"
          component={(props) => (
            <PageTitle title="Login - Report Suspicious Chemical Activity">
              <Login {...props} />
            </PageTitle>
          )}
        />
        <Route
          path="/"
          exact
          render={(props) => <Redirect to="/login" {...props} />}
        />
        <Route
          exact
          path="/home"
          component={(props) => (
            <PageTitle title="Home - Report Suspicious Chemical Activity">
              <Home {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/upload"
          component={(componentProps) => (
            <PageTitle title="Suspicious Activity Report Upload - Report Suspicious Chemical Activity">
              <FileUpload {...componentProps} setUuid={props.setUuid} />{' '}
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/success"
          component={(componentProps) => (
            <PageTitle title="Successful Suspicious Activity Report Upload - Report Suspicious Chemical Activity">
              <Success uuid={props.uuid} {...componentProps} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/error"
          component={() => (
            <PageTitle title="Error - Report Suspicious Chemical Activity">
              <GenericError />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/help"
          component={() => (
            <PageTitle title="Help">
              <Help />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/accessibility-statement"
          component={() => (
            <PageTitle title="Accessibility statement for the Report Suspicious Chemical Activity Service">
              <AccessibilityStatement />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/privacy-policy"
          component={() => (
            <PageTitle title="Personal information notice for the Report Suspicious Chemical Activity Service">
              <PrivacyPolicy />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/cookies"
          component={() => (
            <PageTitle title="Cookies information for the Report Suspicious Chemical Activity Service">
              <Cookies />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/change-cookies"
          component={() => (
            <PageTitle title="Change cookie settings for the Report Suspicious Chemical Activity Service">
              <ChangeCookies />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/something-went-wrong"
          component={() => (
            <PageTitle title="Something went wrong - Report Suspicious Chemical Activity">
              <SomethingWentWrong />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/one-off-sars-error"
          component={() => (
            <PageTitle title="Something went wrong - Report Suspicious Chemical Activity">
              <OneOffSarsError />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/sar-error"
          component={() => (
            <PageTitle title="Something went wrong - Report Suspicious Chemical Activity">
              <SarError />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/error/oneoff"
          component={() => (
            <PageTitle title="Error - Report Suspicious Chemical Activity">
              <GenericError />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/not-found"
          component={() => (
            <PageTitle title="Page Not Found - Report Suspicious Chemical Activity">
              <NotFoundError />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/unauthorised"
          component={() => (
            <PageTitle title="Unauthorised - Report Suspicious Chemical Activity">
              <Unauthorised />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/further-information"
          component={(props) => (
            <PageTitle title="Further Information - Report Suspicious Chemical Activity">
              <FurtherInformation {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/precursor-list"
          component={(props) => (
            <PageTitle title="Precursor List - Report Suspicious Chemical Activity">
              <PrecursorListPage {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/previous-uploads"
          component={(props) => (
            <PageTitle title="Previous Uploads - Report Suspicious Chemical Activity">
              <PreviousUploads {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/sar-registration"
          component={(props) => (
            <PageTitle title="Suspicious Activity Report Registration - Report Suspicious Chemical Activity">
              <OneOffSarRegistration {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/sar-registration/complete"
          component={(props) => (
            <PageTitle title="Suspicious Activity Report Registered - Report Suspicious Chemical Activity">
              <OneOffSarRegistrationComplete {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/sar-registration/not-allowed"
          component={(props) => (
            <PageTitle title="Suspicious Activity Report Registered - Report Suspicious Chemical Activity">
              <OneOffSarRegistrationNotAllowed {...props} />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/sar-details/:uuid"
          component={(props) => (
            <PageTitle title="Suspicious Activity Report Details - Report Suspicious Chemical Activity">
              <SarDetails {...props} />
            </PageTitle>
          )}
        />
        <Route
          path="/submit-sar"
          component={(props) => <OneOffSarsAuth {...props} />}
        />
        <Route path="/sar" render={(props) => <OneOffSars {...props} />} />
        <Route
          exact
          path="/feedback-confirmation"
          component={() => (
            <PageTitle title="Get in touch">
              <FeedbackConfirmation />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/feedback"
          component={() => (
            <PageTitle title="Get in touch">
              <Feedback />
            </PageTitle>
          )}
        />
        <Route
          exact
          path="/link-not-valid"
          component={() => (
            <PageTitle title="Page Not Found - Report Suspicious Chemical Activity">
              <NotFoundError />
            </PageTitle>
          )}
        />
        <Route
          component={() => (
            <PageTitle title="Page Not Found - Report Suspicious Chemical Activity">
              <NotFoundError />
            </PageTitle>
          )}
        />
      </Switch>
    </>
  );
};
