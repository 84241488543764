import React from 'react';

const FeedbackConfirmation = () => {
  return (
    <>
      <main className="govuk-main-wrapper" id="content" role="main">
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l">Thank you for your feedback</h1>
          <p>
            We have received your feedback. Your input will help us to
            continuously improve the service.
          </p>
        </div>
      </main>
    </>
  );
};

export { FeedbackConfirmation };
