import React, { useEffect } from 'react';
import { Form, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { sendFeedbackForm } from './api';
import { TextArea } from '../../OneOffSars/components';
import { Enum } from '../../OneOffSars/components';
import { ErrorList } from '../../OneOffSars/components/ErrorList';

const FeedbackFormSchema = Yup.object().shape({
  feedback: Yup.object().shape({
    satisfaction: Yup.string().required('Select your level of satisfaction'),
    text: Yup.string()
      .max(1200, 'Feedback must be less than 1200 characters')
      .required('Feedback text is required'),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: FeedbackFormSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values) => {
    const feedbackData = {
      satisfaction: values.feedback.satisfaction,
      feedback: values.feedback.text,
    };
    sendFeedbackForm(feedbackData)
      .then(() => {
        window.location.href = '/feedback-confirmation';
      })
      .catch(() => {
        window.location.href = '/something-went-wrong';
      });
  },
});

const FeedbackForm = () => {
  const formik = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Feedback about our services';
  }, []);
  return (
    <div>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <h1 className="govuk-heading-l">Give feedback on this service</h1>
            <h2 className="govuk-heading-m">Satisfaction survey</h2>
            <h3 className="govuk-heading-s">
              Overall, how did you feel completing a one-off report?
            </h3>

            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <fieldset className="govuk-fieldset">
                <div className="govuk-grid-column">
                  <Enum
                    enumValues={[
                      { value: 'Very satisfied' },
                      { value: 'Satisfied' },
                      { value: 'Neither satisfied nor dissatisfied' },
                      { value: 'Dissatisfied' },
                      { value: 'Very dissatisfied' },
                    ]}
                    name="feedback.satisfaction"
                    direction="col"
                  />
                </div>
                <div className="govuk-grid-column">
                  <label htmlFor="feedback.text" id="label-feedback.text">
                    <h3 className="govuk-heading-s">
                      How could we improve this service?
                    </h3>
                  </label>
                  <TextArea
                    name="feedback.text"
                    maxChars={1200}
                    data-testid="feedback.text"
                    id="feedback.text"
                  />
                </div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-send-feedback`}
                  aria-label={'Send feedback'}
                >
                  Send feedback
                </button>
              </fieldset>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};
const FeedbackFormWithFormik = formikWrapper(FeedbackForm);

export { FeedbackFormWithFormik as FeedbackForm };
