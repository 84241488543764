import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { Enum } from '../components';
import { WEIGHT_OR_ITEM_ERROR_MSG } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const ProductInformationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      weight_or_item: Yup.string().required(WEIGHT_OR_ITEM_ERROR_MSG),
    })
  ),
});

const formikWrapper = withFormik({
  validationSchema: ProductInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Weight or Item',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add(`weight-or-item.${values.productIndex}`);
    formikBag.props.updateValues(values, 'unit-details');
  },
});

const WeightOrItem = ({ handleSubmit, values, setFieldValue }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Weight or Item',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Quantity - Report Suspicious Chemical Activity';
  }, []);

  const index = values.productIndex;
  const productNumber = index + 1;
  const exists = values.products.filter((product) => product.id === index);
  if (exists.length === 0) {
    history.goBack();
    return <></>;
  }

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`weight-or-item.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <span className="govuk-caption-l">Product {productNumber}</span>
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[
                    { value: 'By weight or volume' },
                    { value: 'As single items' },
                    {
                      value:
                        'In sales units containing several of the same item',
                    },
                  ]}
                  name={`products.${index}.weight_or_item`}
                  direction="col"
                  values={values}
                  questionLarge="How do you sell this product?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const WeightOrItemWithFormik = formikWrapper(WeightOrItem);

export { WeightOrItemWithFormik as WeightOrItem };
