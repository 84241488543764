const breadcrumbs = [
  {
    currentPage: '/home',
    pattern: /^\/home$/,
    text: 'Home',
  },
  {
    currentPage: '/upload',
    pattern: /^\/upload$/,
    text: 'Upload',
    parent: '/home',
  },
  {
    currentPage: '/success',
    pattern: /^\/success$/,
    text: 'Success',
    parent: '/upload',
  },
  {
    currentPage: '/previous-uploads/',
    pattern: /^\/previous-uploads/,
    text: 'Previous Uploads',
    parent: '/home',
  },
  {
    currentPage: '/sar-details/',
    pattern: /^\/sar-details/,
    text: 'Sar Details',
    parent: '/previous-uploads',
  },
  {
    currentPage: '/error',
    pattern: /^\/error$/,
    text: 'Error',
    parent: '/home',
  },
  {
    currentPage: '/error/oneoff',
    pattern: /^\/error\/oneoff$/,
    text: 'Error',
    parent: '/login',
  },
  {
    currentPage: '/not-found',
    pattern: /^\/not-found$/,
    text: 'Not Found',
    parent: '/home',
  },
  {
    currentPage: '/precursor-list',
    pattern: /^\/precursor-list$/,
    text: 'Precursor List',
    parent: '/home',
  },
  {
    currentPage: '/login',
    pattern: /^\/login$/,
    text: 'Login',
  },
  {
    currentPage: '/something-went-wrong',
    pattern: /^\/something-went-wrong$/,
    text: 'Something went wrong',
    parent: '/home',
  },
];

export const getTrail = (currentPath, existingTrail) => {
  let newItem = breadcrumbs.find((item) => currentPath.match(item.pattern));
  if (!newItem) {
    return null;
  }

  let trail = [newItem];
  if (existingTrail) {
    trail = trail.concat(existingTrail);
  }
  if (newItem.parent) {
    trail = getTrail(newItem.parent, trail);
  }

  if (trail && trail.length === 1 && trail[0].text === 'Login') {
    trail = null;
  }

  return trail;
};
