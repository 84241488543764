import React from 'react';
import { Link } from 'react-router-dom';

export const FurtherInformation = () => {
  return (
    <div>
      <div className="govuk-breadcrumbs">
        <ol className="govuk-breadcrumbs__list">
          <li className="govuk-breadcrumbs__list-item">
            <Link to="/">
              <p className="govuk-breadcrumbs__link">Home</p>
            </Link>
          </li>
          <li className="govuk-breadcrumbs__list-item">
            <Link to="/upload">
              <p className="govuk-breadcrumbs__link">Submit SAR</p>
            </Link>
          </li>
        </ol>
        <Link to="/upload">
          <p className="govuk-back-link">Back</p>
        </Link>
      </div>

      <main className="govuk-main-wrapper">
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <div className="govuk-form-group">
              <label className="govuk-label">
                Can you provide more detail?
              </label>
              <span id="more-detail-hint" className="govuk-hint">
                Can you provide anymore information about this transaction that
                could help with our investigation?
              </span>
              <textarea
                className="govuk-textarea"
                id="more-detail"
                name="more-detail"
                rows="5"
                aria-describedby="more-detail-hint"
              ></textarea>
            </div>
          </div>
          <div className="govuk-grid-column-two-thirds">
            <button
              onClick={null}
              style={{ marginTop: '20px' }}
              type="submit"
              data-prevent-double-click="true"
              className="govuk-button"
              data-module="govuk-button"
            >
              Continue
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
