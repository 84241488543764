import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { Enum } from '../components';
import { IS_DISCOUNT_ERROR_MSG } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const ProductInformationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      is_discount: Yup.string().required(IS_DISCOUNT_ERROR_MSG),
    })
  ),
});

const formikWrapper = withFormik({
  validationSchema: ProductInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Is Discount',
      action: 'Completed',
    });
    delete values.matomo;
    const nextPage =
      values.products[values.productIndex].is_discount === 'Yes'
        ? 'discount-details'
        : 'product-review';
    values.sections.add(`is-discount.${values.productIndex}`);
    formikBag.props.updateValues(values, nextPage);
  },
});

const IsDiscount = ({ handleSubmit, values, setFieldValue }) => {
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };
  const history = useHistory();

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Is Discount',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Discount - Report Suspicious Chemical Activity';
  }, []);

  const index = values.productIndex;
  const productNumber = index + 1;
  const exists = values.products.filter((product) => product.id === index);
  if (exists.length === 0) {
    history.goBack();
    return <></>;
  }

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`is-discount.${index}`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <span className="govuk-caption-l">Product {productNumber}</span>
            <form onSubmit={handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[{ value: 'Yes' }, { value: 'No' }]}
                  name={`products.${index}.is_discount`}
                  direction="col"
                  values={values}
                  questionLarge="Was a discount or promotion applied to the product?"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const IsDiscountWithFormik = formikWrapper(IsDiscount);

export { IsDiscountWithFormik as IsDiscount };
