import React from 'react';
import { FormGroup } from '../';
import { SelectLabel } from './SelectLabel';

export const Select = ({
  name,
  label,
  onChange,
  selected,
  options,
  disabled = false,
  dataTest,
}) => {
  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <FormGroup>
      {label ? <SelectLabel htmlFor={name}>{label}</SelectLabel> : null}
      <select
        className={'govuk-select'}
        name={name}
        id={name}
        onChange={handleOnChange}
        value={selected}
        disabled={disabled}
        data-test={dataTest}
      >
        {options.map(({ value, description }) => (
          <option value={value} key={value}>
            {description}
          </option>
        ))}
      </select>
    </FormGroup>
  );
};
