import React from 'react';

const NotFoundError = () => {
  return (
    <div>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l" data-test="error-header">
            Page not found
          </h1>
          <p className="govuk-body" data-test="error-text-1">
            If you typed the web address, check it is correct.
          </p>
          <p className="govuk-body" data-test="error-text-1">
            If you pasted the web address, check you copied the entire address.
          </p>
          <p className="govuk-body" data-test="error-text-2">
            If the web address is correct or you selected a link or button,
            please contact{' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            .
          </p>
        </div>
      </main>
    </div>
  );
};
export default NotFoundError;
