import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { getErrorPath, getLabelledFlatErrorList } from '../../validation-utils';

export const ErrorList = ({ questionTitles }) => {
  const formik = useFormikContext();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const errors = getLabelledFlatErrorList(formik.errors, questionTitles);
    if (errors.length > 0) {
      const scrollTop = document.getElementById('content').offsetTop;
      window.scrollTo(0, scrollTop);
      setErrors(errors);
    }
  }, [formik.errors, getLabelledFlatErrorList, formik.isValidating]);

  useEffect(() => {
    const hasErrors = Object.keys(formik.errors).length > 0;
    if (hasErrors) {
      document.getElementById('govuk-error-summary').focus();
    }
  }, [errors]);

  const handleClick = (e, errorPath) => {
    e.preventDefault();
    let path = getErrorPath(errorPath);
    if (errorPath && document.getElementsByName(errorPath).length) {
      document.querySelector(`[name="${errorPath}"]`).focus();
      document.querySelector(`[id="label-${path}"]`).scrollIntoView();
    }
    return false;
  };

  return (
    errors &&
    errors.length > 0 && (
      <div
        className="govuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabIndex="-1"
        data-module="govuk-error-summary"
        id="govuk-error-summary"
      >
        <h2 className="govuk-error-summary__title" id="error-summary-title">
          There is a problem
        </h2>
        <div className="govuk-error-summary__body">
          <ul className="govuk-list govuk-error-summary__list">
            {errors.map((error, index) => (
              <li key={index}>
                <a href={''} onClick={(e) => handleClick(e, error.path)}>
                  {error.message}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
};
