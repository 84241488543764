import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getConfig } from '../../Config';
import { LoadingSpinner } from '../../components/Govuk/Spinner/LoadingSpinner';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { UploadMessage } from '../../containers/UploadMessage';
import moment from 'moment';

const SarDetails = (props) => {
  const [sarReport, setSarReport] = useState([]);
  const [sarReportRecords, setSarReportRecords] = useState([]);
  const [loadingSarReport, setLoadingSarReport] = useState(true);
  const [loadingSarRecords, setLoadingSarRecords] = useState(true);
  const [uuid, setUuid] = useState();
  const sarsReportsEndpoint = getConfig().apiBaseUrl;
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    const { uuid } = props.match.params;
    setUuid(uuid);

    getSarRows(uuid);
    getSarDetails(uuid);

    trackPageView();
    trackEvent({
      category: 'Uploaded Suspicious Activity Report Details',
      action: 'Visited',
    });
  }, []);

  const downloadFile = () => {
    axios({
      url: sarsReportsEndpoint + '/sars-reports/_native/' + uuid,
      method: 'GET',
      responseType: 'blob', // important
      headers: { accept: 'application/octet-stream' },
    })
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let downloadElement = document.createElement('a');
        downloadElement.href = url;
        downloadElement.download = sarReport.source_name;
        downloadElement.click();
      })
      .catch(() => {
        props.history.push(`/sar-error`);
      });
  };

  function getSarDetails(uuid) {
    axios({
      url: sarsReportsEndpoint + '/sars-reports/' + uuid,
      method: 'GET',
      headers: { accept: '*/*' },
    })
      .then((response) => {
        let tmpReports = [];
        tmpReports = response.data.sourceAsMap;
        setSarReport(tmpReports);
        setLoadingSarReport(false);
      })
      .catch(() => {
        props.history.push(`/sar-error`);
      });
  }

  function getSarRows(uuid) {
    axios({
      url: sarsReportsEndpoint + '/sars-reports/' + uuid + '/sars',
      method: 'GET',
      headers: { accept: '*/*' },
    })
      .then((response) => {
        const tmpReportRecords = [];
        response.data.hits.hits.map((report) =>
          tmpReportRecords.push(report.sourceAsMap)
        );
        tmpReportRecords.sort(sarRecordComparator);
        setSarReportRecords(tmpReportRecords);
        setLoadingSarRecords(false);
      })
      .catch(() => {});
  }

  const sarRecordComparator = (a, b) => {
    return a.record_position > b.record_position ? 1 : -1;
  };

  const hasMessage = () => {
    let value = false;

    sarReportRecords.map((record) => {
      if (record.issues && record.issues.length > 0) {
        value = true;
      }
    });

    return value;
  };

  const within90Days = moment().diff(sarReport.timestamp, 'days') <= 90;

  const isOneOffSar = () => {
    const oneOffSarsFileName = 'one-off-sar.json';
    return sarReport.source_name.includes(oneOffSarsFileName);
  };

  return (
    <>
      <main className="govuk-main-wrapper" id="content">
        <h2 className="govuk-heading-l" data-test="sar-details-header">
          Uploaded Suspicious Activity Report Details
        </h2>
        {!loadingSarReport && !loadingSarRecords ? (
          <>
            <div className="govuk-grid-row">
              <div className="govuk-grid-column-two-thirds">
                <dl className="govuk-summary-list">
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="status-label"
                    >
                      Status
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="status-value"
                    >
                      {sarReport.status}
                    </dd>
                  </div>
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="records-processed-label"
                    >
                      Records processed
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="records-processed-value"
                    >
                      {sarReport.record_count}
                    </dd>
                  </div>
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="records-failed-label"
                    >
                      Records failed processing
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="records-failed-value"
                    >
                      {sarReport.error_count}
                    </dd>
                  </div>
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="uploaded-by-label"
                    >
                      Uploaded By
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="uploaded-by-value"
                    >
                      {sarReport.user}
                    </dd>
                  </div>
                  {!isOneOffSar() && (
                    <div className="govuk-summary-list__row">
                      <dt
                        className="govuk-summary-list__key"
                        data-test="retailer-name-label"
                      >
                        Retailer Name
                      </dt>
                      <dd
                        className="govuk-summary-list__value"
                        data-test="retailer-name-value"
                      >
                        {sarReport.retailer}
                      </dd>
                    </div>
                  )}
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="unique-id-label"
                    >
                      Unique ID
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="unique-id-value"
                    >
                      {sarReport.source_id}
                    </dd>
                  </div>
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="message-label"
                    >
                      Message
                    </dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="message-value"
                    >
                      <UploadMessage message={sarReport.message} />
                    </dd>
                  </div>
                  <div className="govuk-summary-list__row">
                    <dt
                      className="govuk-summary-list__key"
                      data-test="attachment-label"
                    >
                      Attachments
                    </dt>

                    {within90Days ? (
                      <dd className="govuk-summary-list__value govuk-link link">
                        <a
                          onClick={downloadFile}
                          tabIndex={0}
                          onKeyDown={(e) => {
                            const code = e.which;
                            //13 = Return, 32 = Space
                            if (code === 13 || code === 32) {
                              downloadFile();
                            }
                          }}
                          data-test="attachment-value"
                        >
                          {sarReport.source_name}
                        </a>
                      </dd>
                    ) : (
                      <dd
                        className="govuk-summary-list__value"
                        data-test="message-value"
                      >
                        Reports uploaded more than 90 days ago cannot be
                        downloaded from this platform. Please email{' '}
                        <a href="mailto:DACC.OSCT15@homeoffice.gov.uk">
                          DACC.OSCT15@homeoffice.gov.uk
                        </a>{' '}
                        with details of the relevant report if you would like to
                        access a report that was uploaded more than 90 days ago.
                      </dd>
                    )}
                  </div>
                </dl>
              </div>
            </div>
            {sarReportRecords && sarReportRecords.length > 0 && hasMessage() ? (
              <>
                <h3 className="govuk-heading-s" data-test="rows-header">
                  Row Details
                </h3>
                <table className="govuk-table">
                  <thead>
                    <tr className="govuk-table__row">
                      <th
                        scope="col"
                        className="govuk-table__header"
                        data-test="record-position-th"
                      >
                        Record Position
                      </th>
                      <th
                        scope="col"
                        className="govuk-table__header"
                        data-test="message-th"
                      >
                        Message
                      </th>
                      <th
                        scope="col"
                        className="govuk-table__header"
                        data-test="id-th"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="govuk-table__header"
                        data-test="status-th"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="govuk-table__body">
                    {sarReportRecords.map((report) => {
                      return (
                        <tr key={report.source_id} className="govuk-table__row">
                          <td
                            className="govuk-table__cell"
                            data-test={`record-position-${report.filename}`}
                          >
                            {report.record_position}
                          </td>
                          <td
                            className="govuk-table__cell"
                            data-test={`record-${report.filename}`}
                          >
                            {report.issues &&
                              report.issues.length > 0 &&
                              report.issues.map((issue, index) => {
                                return (
                                  <ul
                                    style={{
                                      listStyle: 'none',
                                      marginBottom: '2px',
                                      padding: '0px',
                                    }}
                                  >
                                    <li
                                      data-test={`record-${report.record_position}-issue-${index}`}
                                    >
                                      {issue.message}
                                    </li>
                                  </ul>
                                );
                              })}
                          </td>
                          <td
                            className="govuk-table__cell"
                            data-test={`record-filename-${report.filename}`}
                          >
                            {report.filename}
                          </td>
                          <td
                            className="govuk-table__cell"
                            data-test={`record-status-${report.filename}`}
                          >
                            {report.status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </main>
    </>
  );
};

const SarDetailsWithAuth = withAuthorisation({
  forwardUrl: '/previous-uploads',
})(withRouter(SarDetails));

export { SarDetailsWithAuth as SarDetails };
