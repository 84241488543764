import axios from 'axios';
import { getConfig } from '../../Config';

const portalPublicEndpoint = getConfig().portalPublicUrl;

export const sendFormAnswers = (sar, accessToken) => {
  const sarCopy = Object.assign({}, sar);
  sarCopy.sections = Array.from(sarCopy.sections);
  return axios.post(
    `${portalPublicEndpoint}/sar`,
    { ...sarCopy },
    {
      headers: { Authorization: accessToken },
    }
  );
};
