import React, { useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getCookie, setSeen, setConsent } from '../../cookie';

const CookieBanner = () => {
  const { pushInstruction } = useMatomo();
  const hasSeen = getCookie('seen_cookie_message') !== '';
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [hidden, setHidden] = useState(hasSeen);

  const acceptOnClick = () => {
    setAccepted(true);
    setSeen('Yes');
    setConsent(pushInstruction, 'Yes');
  };

  const rejectOnClick = () => {
    setRejected(true);
    setSeen('No');
    setConsent(pushInstruction, 'No');
  };

  return (
    <>
      <div
        className="govuk-cookie-banner "
        data-nosnippet
        role="region"
        aria-label="Cookies on Report suspicious chemical activity"
        hidden={hidden ? 'hidden' : ''}
      >
        <div
          className="govuk-cookie-banner__message govuk-width-container"
          hidden={accepted || rejected ? 'hidden' : ''}
        >
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-thirds">
              <h2 className="govuk-cookie-banner__heading govuk-heading-m">
                Cookies on Report suspicious chemical activity
              </h2>

              <div className="govuk-cookie-banner__content">
                <p>We use some essential cookies to make this service work.</p>
                <p>
                  We’d also like to use analytics cookies so we can understand
                  how you use the service and make improvements.
                </p>
              </div>
            </div>
          </div>

          <div className="govuk-button-group">
            <button
              type="button"
              data-test="accept-cookies-button"
              className="govuk-button"
              data-module="govuk-button"
              onClick={acceptOnClick}
            >
              Accept analytics cookies
            </button>
            <button
              type="button"
              data-test="reject-cookies-button"
              className="govuk-button"
              data-module="govuk-button"
              onClick={rejectOnClick}
            >
              Reject analytics cookies
            </button>
            <a className="govuk-link" href="/cookies">
              View cookies
            </a>
          </div>
        </div>

        <div
          className="govuk-cookie-banner__message govuk-width-container"
          role="alert"
          hidden={accepted ? '' : 'hidden'}
        >
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-thirds">
              <div className="govuk-cookie-banner__content">
                <p>
                  You’ve accepted analytics cookies. You can{' '}
                  <a className="govuk-link" href="/change-cookies">
                    change your cookie settings
                  </a>{' '}
                  at any time.
                </p>
              </div>
            </div>
          </div>

          <div className="govuk-button-group">
            <button
              className="govuk-button"
              data-test="hide-accept-cookies-button"
              data-module="govuk-button"
              onClick={() => setHidden(true)}
            >
              Hide this message
            </button>
          </div>
        </div>

        <div
          className="govuk-cookie-banner__message govuk-width-container"
          role="alert"
          hidden={rejected ? '' : 'hidden'}
        >
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-thirds">
              <div className="govuk-cookie-banner__content">
                <p>
                  You’ve rejected analytics cookies. You can{' '}
                  <a className="govuk-link" href="/change-cookies">
                    change your cookie settings
                  </a>{' '}
                  at any time.
                </p>
              </div>
            </div>
          </div>

          <div className="govuk-button-group">
            <button
              className="govuk-button"
              data-test="hide-reject-cookies-button"
              data-module="govuk-button"
              onClick={() => setHidden(true)}
            >
              Hide this message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { CookieBanner };
