import React from 'react';

const SomethingWentWrong = () => {
  return (
    <main
      className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
      id="content"
      role="main"
      tabIndex={0}
    >
      <h1 data-test="error-header">
        Sorry, there is a problem with the service
      </h1>
      <p data-test="error-text-1">
        The service is currently experiencing an issue, please retry later.
      </p>
      <p>
        Please contact{' '}
        <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
          ChemicalReportingTeam@homeoffice.gov.uk
        </a>{' '}
        for further information.
      </p>
    </main>
  );
};
export default SomethingWentWrong;
