import axios from 'axios';

export const handleTokenExpiredEvent = (keycloak) => {
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(300)
      .then(() => {
        sessionStorage.setItem('token', keycloak.token);
        axios.interceptors.request.use((request) => {
          const token = sessionStorage.getItem('token');
          if (token) {
            request.headers.Authorization = token;
          }
          return request;
        });
      })
      .catch(() => {
        // If updating the token fails invalidate the session to force login when user performs an action
        keycloak.clearToken();
        sessionStorage.clear();
        sessionStorage.setItem('authenticated', 'false');
        keycloak.init({
          promiseType: 'native',
          checkLoginIframe: false,
        });
      });
  };
};
