import React from 'react';
import { withFormik } from 'formik';
import { Enum } from '../OneOffSars/components';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getCookie, setSeen, setConsent } from '../../cookie';
import { Link } from 'react-router-dom';

const formikWrapper = withFormik({
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: () => ({
    cookieAcceptance: getCookie('seen_cookie_message'),
  }),
  handleSubmit: (values, { setStatus, props }) => {
    setStatus({
      sent: true,
    });
    setSeen(values.cookieAcceptance);
    setConsent(props.pushInstruction, values.cookieAcceptance);
    window.scrollTo(0, 0);
  },
});

const ChangeCookies = ({ handleSubmit, values, status }) => {
  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          {status && (
            <div
              className="govuk-notification-banner govuk-notification-banner--success"
              role="alert"
              aria-labelledby="govuk-notification-banner-title"
              data-module="govuk-notification-banner"
            >
              <div className="govuk-notification-banner__header">
                <h2
                  className="govuk-notification-banner__title"
                  id="govuk-notification-banner-title"
                >
                  Success
                </h2>
              </div>
              <div className="govuk-notification-banner__content">
                <p className="govuk-notification-banner__heading">
                  You’ve set your cookie preferences.
                </p>
              </div>
            </div>
          )}
          <h1 className="govuk-heading-l">Change your cookie settings</h1>
          <p className="govuk-body">
            Cookies are files saved on your phone, tablet or computer when you
            visit a website.
          </p>
          <p className="govuk-body">
            We use cookies to store information about how you use Report
            Suspicious Chemical Activity, such as the pages you visit.
          </p>
          <h2 className="govuk-heading-m">Cookie settings</h2>
          <p className="govuk-body">
            We use different types of cookies. You can choose which cookies
            you're happy for us to use and how the data is shared.
          </p>
          <p className="govuk-body">
            To successfully change your cookie settings, you must have one of
            the following browsers:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>Internet Explorer version 10 and above</li>
              <li>Firefox version 38 and above</li>
              <li>Chrome version 45 and above</li>
              <li>Safari version 8 and above</li>
            </ul>
          </p>
          <h2 className="govuk-heading-m">Strictly necessary cookies</h2>
          <p className="govuk-body">
            We use essential cookies to help you use the ‘Report Suspicious
            Chemical Activity’ service. These do things such as:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>remember your progress through our service</li>
              <li>remember you have seen the cookies message</li>
              <li>
                remember the language you have selected to view our service
              </li>
            </ul>
          </p>
          <p className="govuk-body">
            These cookies always need to be on to give you the best experience
            using the service.
          </p>
          <p className="govuk-body">
            <Link to="cookies">Read more about cookies policy</Link>
          </p>
          <h2 className="govuk-heading-m">
            Cookies used to improve this service
          </h2>
          <p className="govuk-body">
            We use Matomo to measure how you use ‘Report Suspicious Chemical
            Activity’ so we can improve it based on user needs. We do not allow
            Matomo to use or share the data about how you use this site.
          </p>
          <p className="govuk-body">
            By accepting analytics cookies, you agree to the Home Office using
            your data to help improve the 'Report Suspicious Chemical Activity'
            Service.
          </p>
          <p className="govuk-body">
            <Link to="privacy-policy">
              Find out how data is shared when you use this service
            </Link>
            .
          </p>
          <form onSubmit={handleSubmit}>
            <div className="govuk-grid-column">
              <Enum
                enumValues={[{ value: 'Yes' }, { value: 'No' }]}
                name={`cookieAcceptance`}
                direction="col"
                values={values}
                question="Do you want to accept analytics cookies?"
              />
            </div>
            <button
              type="submit"
              data-prevent-double-click="true"
              className="govuk-button"
              data-module="govuk-button"
              data-test={`button-save-cookie`}
              aria-label="save"
            >
              Save cookie settings
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

const ChangeCookiesWithFormik = formikWrapper(ChangeCookies);

const ChangeCookiesWithMatomo = () => {
  const { pushInstruction } = useMatomo();
  return <ChangeCookiesWithFormik pushInstruction={pushInstruction} />;
};

export { ChangeCookiesWithMatomo as ChangeCookies };
