/*eslint no-irregular-whitespace: ["error", { "skipJSXText": true }]*/
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l">
            Explosives precursors and poisons reporting: personal information
            notice
          </h1>
          <p className="govuk-body">
            Your personal information, supplied for the purpose of safeguarding
            public safety and security when making a report about a suspicious
            incident relating to explosives precursors and/or poisons in
            accordance with the Poisons Act 1972, will be held and processed by
            the Home Office.
          </p>
          <div className="govuk-body address">
            <div className="adr org fn">
              <p>
                Home Office
                <br />
                Homeland Security Group
                <br />
                5th floor Peel Building
                <br />2 Marsham Street
                <br />
                London
                <br />
                SW1P 4DF
              </p>
            </div>
          </div>
          <p className="govuk-body">
            The Home Office is the controller of this information. This also
            includes when it is collected or processed by third parties on our
            behalf.
          </p>
          <p className="govuk-body">
            Details of the department’s Data Protection Officer:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                Postal address:
                <div className="govuk-body address">
                  <div className="adr org fn">
                    <p>
                      Office of the DPO
                      <br />
                      Peel Building
                      <br />2 Marsham Street
                      <br />
                      London
                      <br />
                      SW1P 4DF
                    </p>
                  </div>
                </div>
              </li>
              <li>
                Email: 
                <a href="mailto:dpo@homeoffice.gov.uk">dpo@homeoffice.gov.uk</a>
              </li>
              <li>Telephone number: 020 7035 6999</li>
            </ul>
          </p>
          <h2 className="govuk-heading-m">
            Changes to this personal information notice
          </h2>
          <p className="govuk-body">
            The Home Office may amend this personal information notice (PIN)
            from time to time. If the Home Office makes any substantial changes
            in the way its officials use your personal information, the Home
            Office will make that information available by amending this and any
            associated notices.
          </p>
          <h2 className="govuk-heading-m">
            How and why the department uses your information
          </h2>
          <p className="govuk-body">
            The Home Office collects, processes, and shares personal information
            to enable it to carry out its statutory and other functions,
            including those related to law enforcement, safeguarding against,
            and the prevention of, threats to public security. For this purpose
            the Home Office is a competent authority within the meaning of
            Schedule 7 of the Data Protection Act 2018.
          </p>
          <p className="govuk-body">
            We are only allowed to process your data where there is a lawful
            basis for doing so. The Home Office is processing this information
            because it is necessary for the performance of a task carried out
            for law enforcement purposes by a competent authority.
          </p>
          <p className="govuk-body">
            We will process the personal data that you provide about yourself
            and any data you provide on behalf of others, including name, email
            address and address, in order to prevent, investigate, detect or
            prosecute criminal offences. We may share your information with
            other organisations in the course of carrying out our functions, or
            to enable others to perform theirs and which are consistent with the
            Home Office functions.
          </p>
          <p className="govuk-body">
            Your personal information is being processed under this notice on
            the basis that you are a witness or other person with information in
            relation to an offence or potential offence.
          </p>
          <p className="govuk-body">
            More information about the ways in which the Home Office may use
            your personal information can be found on the{' '}
            <a
              href="https://www.gov.uk/government/organisations/home-office/about/personal-information-charter"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal information charter
            </a>
            , including the purposes for which we use it, the legal basis, and
            who we share your information with.
          </p>
          <p className="govuk-body">
            The personal information we have collected about you will be shared
            with the Police and emergency services, and other government
            departments, agencies and public bodies where it is required; to
            protect life, prevent crime, under the terms of any court order, or
            to comply with lawful obligations for the purpose of preventing
            crime and protecting the public.
          </p>
          <h2 className="govuk-heading-m">Storing your information</h2>
          <p className="govuk-body">
            Your personal information will be held for as long as necessary for
            the purpose for which it is being processed and in line with
            departmental retention policy. We 
            <a
              href="https://www.gov.uk/government/publications/home-office-retention-and-disposal-standards"
              target="_blank"
              rel="noopener noreferrer"
            >
              retain your data
            </a>{' '}
            for 6 years.
          </p>
          <h2 className="govuk-heading-m">
            Requesting access to your personal data
          </h2>
          <p className="govuk-body">
            You have the right to request access to the personal information we
            hold about you.
          </p>
          <p className="govuk-body">
            Email:
            <a href="mailto:info.access@homeoffice.gov.uk">
              info.access@homeoffice.gov.uk
            </a>
          </p>
          <p className="govuk-body">Or write to us at:</p>
          <div className="govuk-body address">
            <div className="adr org fn">
              <p>
                Information Rights Team
                <br />
                Knowledge and Information Management Unit
                <br />
                Performance and Risk Directorate
                <br />
                Home Office 3rd Floor, Peel Building
                <br />2 Marsham Street
                <br />
                London
                <br />
                SW1P 4DF
              </p>
            </div>
          </div>
          <h2 className="govuk-heading-m">Other rights</h2>
          <p className="govuk-body">
            In certain circumstances you have the right to:
          </p>
          <p className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                ask us to erase or correct your personal data if it is
                inaccurate.
              </li>
            </ul>
          </p>
          <h2 className="govuk-heading-m">
            Questions or concerns about personal data
          </h2>
          <p className="govuk-body">
            If you have any questions or concerns about our collection, use or
            disclosure of your personal information please contact us at:
          </p>
          <p className="govuk-body">
            Email:
            <a href="mailto:public.enquiries@homeoffice.gov.uk">
              public.enquiries@homeoffice.gov.uk
            </a>
          </p>
          <p className="govuk-body">
            You may contact the{' '}
            <a href="mailto:dpo@homeoffice.gov.uk">Data Protection Officer</a>{' '}
            (contact details can be found at the start of this document) direct
            about the way that the Home Office is handling your personal
            information, or if you believe we should have provided you with more
            information about why/how we are processing it.
          </p>
          <p className="govuk-body">
            You have the right to complain to the 
            <a
              href="https://ico.org.uk/concerns"
              target="_blank"
              rel="noopener noreferrer"
            >
              Information Commissioner’s Office
            </a>{' '}
            about the way the Home Office is handling your personal information,
            or if you believe we should have provided you with more information
            about why/how we are processing it.
          </p>
        </div>
      </main>
    </>
  );
};

export { PrivacyPolicy };
