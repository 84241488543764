import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { InputBox } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  LENGTH_FOUR_ERROR,
  LENGTH_SIX_ERROR,
  NUMBER_ERROR_MESSAGE,
} from '../validation-utils';

const digitsOnly = (value) => /^\d+$/.test(value);

const questionTitles = {
  transaction: {
    card_first_six: 'First six digits',
    card_last_four: 'Last four digits',
  },
};

const BankDetailsSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    card_first_six: Yup.string()
      .test('lenSix', LENGTH_SIX_ERROR, (val) => {
        if (typeof val !== 'undefined') return val.toString().length === 6;
        return true;
      })
      .test('Digits only', NUMBER_ERROR_MESSAGE, digitsOnly),
    card_last_four: Yup.string()
      .test('lenFour', LENGTH_FOUR_ERROR, (val) => {
        if (typeof val !== 'undefined') return val.toString().length === 4;
        return true;
      })
      .test('Digits only', NUMBER_ERROR_MESSAGE, digitsOnly),
  }),
});

const formikWrapper = withFormik({
  validationSchema: BankDetailsSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Bank Details',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'more-info';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('bank-details');
    formikBag.props.updateValues(values, next);
  },
});

const BankDetails = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Transaction Bank Details',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Bank details - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`bank-details`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList questionTitles={questionTitles} />
            <span className="govuk-caption-l">Transaction details</span>
            <Form onSubmit={formik.handleSubmit}>
              <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 className="govuk-fieldset__heading">
                  Bank or credit card details
                </h1>
              </legend>
              <div className="govuk-hint">
                If you’re willing to provide these enter the buyer’s account
                number or credit card number.
              </div>
              <div className="govuk-grid-column">
                <InputBox
                  question={questionTitles.transaction.card_first_six}
                  name="transaction.card_first_six"
                  size="20"
                />
                <InputBox
                  question={questionTitles.transaction.card_last_four}
                  name="transaction.card_last_four"
                  size="20"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const BankDetailsWithFormik = formikWrapper(BankDetails);

export { BankDetailsWithFormik as BankDetails, BankDetailsSchema };
