export const getCookie = (name) => {
  const match = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return match ? match[1] : '';
};

const oneDayInSeconds = 86400;
const thirtyOneDaysInSeconds = 31 * oneDayInSeconds;

export const setSeen = (isAccepted) => {
  document.cookie = `seen_cookie_message=${isAccepted};max-age=${thirtyOneDaysInSeconds}; path=/`;
};

export const setConsent = (pushInstruction, constent) => {
  if (constent === 'Yes') {
    pushInstruction('setConsentGiven');
    pushInstruction('setConsentGiven');
  } else {
    pushInstruction('forgetConsentGiven');
  }
};
