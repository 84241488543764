import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { TextArea, InputBox, Date, Address } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  getDobValidationSchema,
  EMAIL_ERROR_MSG,
  TELEPHONE_ERROR_MESSAGE,
  TELEPHONE_MATCHER,
} from '../validation-utils';
import { useHistory } from 'react-router-dom';
import { ErrorList } from '../components/ErrorList';

const questionTitles = {
  about_person: {
    crime_details: {
      email: 'Email address',
      telephone: 'Phone number',
      dob: 'What is their date of birth?',
    },
  },
};

const TheftPersonDetailsSchema = Yup.object().shape({
  about_person: Yup.object().shape({
    crime_details: Yup.object().shape({
      email: Yup.string().email(EMAIL_ERROR_MSG),
      telephone: Yup.string().matches(
        TELEPHONE_MATCHER,
        TELEPHONE_ERROR_MESSAGE
      ),
      ...getDobValidationSchema(),
    }),
  }),
});

const formikWrapper = withFormik({
  validationSchema: TheftPersonDetailsSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Theft Person Details',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'payment-method';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('suspicious-person');
    formikBag.props.updateValues(values, next);
  },
});

const TheftPersonDetails = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  formik.values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Theft Person Details',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'About the person - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`suspicious-person`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">About the suspicious person</h1>
            <ErrorList questionTitles={questionTitles} />
            <p className="govuk-body">
              Add any information you have available about the person(s) who
              tried to obtain the chemical products.
            </p>
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <TextArea
                  heading="Describe the person or people"
                  name="about_person.crime_details.description"
                  subText="If you saw the person(s) who tried to steal the chemicals describe them here. For example, how they acted, their height, age, gender, ethnicity and distinguishing features. If the incident took place via phone, tell us about any identifying characteristics, such as voice or accent"
                  style="l"
                />
                <InputBox
                  question="Full name"
                  name="about_person.crime_details.fullname"
                />
                <InputBox
                  question={questionTitles.about_person.crime_details.email}
                  name="about_person.crime_details.email"
                />
                <InputBox
                  question={questionTitles.about_person.crime_details.telephone}
                  isNumber={true}
                  name="about_person.crime_details.telephone"
                  size="20"
                  subText="Enter their phone number. If it’s an international number, start with the country code - for example, +33."
                />
                <Date
                  question={questionTitles.about_person.crime_details.dob}
                  name="about_person.crime_details.dob"
                  subText="For example, 31 3 1980. If you don't know, you can leave this blank."
                />
                <Address
                  question="Address"
                  name="about_person.crime_details.address"
                  subText="If you don't know you can leave this blank."
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test="button-continue"
                aria-label="Continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const TheftPersonDetailsWithFormik = formikWrapper(TheftPersonDetails);

export { TheftPersonDetailsWithFormik as TheftPersonDetails };
