import moment from 'moment';
import _ from 'lodash';

export const fromDateAsIso = ({ year, month, day }) =>
  moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD')
    .startOf('day')
    .toISOString();

export const toDateAsIso = ({ year, month, day }) =>
  moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD')
    .endOf('day')
    .toISOString();

export const noop = (e) => e.preventDefault();

export const getPageRange = (page, lastPage) => {
  const upperPage = Math.ceil(page / 5) * 5;
  const lowerPageRange = upperPage - 4;
  const upperPageRange = upperPage > lastPage ? lastPage : upperPage;
  return _.range(lowerPageRange, upperPageRange + 1);
};

export const isCurrentPage = (page, pageToCheck) =>
  pageToCheck === page ? 'current' : '';
