import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import get from 'lodash/get';
import classNames from 'classnames';
import { getFirstErrorFieldName } from '../../validation-utils';

export const Enum = ({
  question,
  enumValues,
  subText,
  direction,
  style,
  hintId,
  name,
  values,
  questionLarge,
}) => {
  const formik = useFormikContext();
  const [error, setError] = useState();
  const [errorPath, setErrorPath] = useState();
  useEffect(() => {
    setError(get(formik.errors, name));
  }, [formik.errors, get, name]);
  useEffect(() => {
    const errorPath = getFirstErrorFieldName(formik.errors);
    setErrorPath(errorPath);
  }, [formik.errors, getFirstErrorFieldName]);
  const setRadioOptions = (enumValue, key) => {
    const { value, conditionalName, conditionalLabel, optionHint } = enumValue;
    return (
      <React.Fragment key={key}>
        <div className="govuk-radios__item" key={`label-${key}`}>
          <Field
            className="govuk-radios__input"
            id={`${name}-${key}`}
            name={`${name}`}
            data-test={`${name}-${enumValues[key].value}`}
            type="radio"
            value={enumValues[key].value}
            data-aria-controls={
              conditionalName ? `conditional-${conditionalName}` : undefined
            }
          />
          <label
            className="govuk-label govuk-radios__label"
            id={`label-${name}`}
            htmlFor={`${name}-${key}`}
          >
            {value}
          </label>
          {optionHint ? (
            <div className="govuk-hint" style={{ paddingLeft: '16px' }}>
              {' '}
              {optionHint}
            </div>
          ) : (
            ''
          )}
        </div>
        {_.get(values, name) === value && conditionalName ? (
          <div
            className="govuk-radios__conditional govuk-radios__conditional--hidden"
            id={`conditional-${conditionalName}`}
            key={`condition-${key}`}
          >
            <div
              className={classNames('govuk-form-group', {
                'govuk-form-group--error': errorPath === conditionalName,
              })}
            >
              <label
                className="govuk-label"
                htmlFor={conditionalName}
                id={`label-${conditionalName}`}
              >
                {conditionalLabel}
              </label>
              {errorPath === conditionalName && (
                <span
                  id={`${conditionalName}.error`}
                  className="govuk-error-message"
                >
                  <span className="govuk-visually-hidden">Error:</span>
                  {get(formik.errors, conditionalName)}
                </span>
              )}
              <Field
                className="govuk-input govuk-!-width-one-third"
                id={conditionalName}
                name={conditionalName}
                data-test={conditionalName}
                inputMode={'text'}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  let radioClassName =
    direction === 'col' ? 'govuk-radios' : 'govuk-radios govuk-radios--inline';
  const hasConditional = _.find(enumValues, 'conditionalName');
  if (hasConditional) {
    radioClassName = radioClassName + ' govuk-radios--conditional';
  }

  return (
    <div
      className={classNames('govuk-form-group', {
        'govuk-form-group--error': error,
      })}
    >
      <fieldset className="govuk-fieldset">
        {(() => {
          if (question) {
            return style === 'h2' ? (
              <legend className="govuk-fieldset__legend govuk-fieldset__legend--m">
                <h2 className="govuk-fieldset__heading">{question}</h2>
                {subText && (
                  <div id={hintId} className="govuk-hint">
                    <p>{subText}</p>
                  </div>
                )}
              </legend>
            ) : (
              <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                <label className="govuk-label">{question}</label>
                {subText && (
                  <div id={hintId} className="govuk-hint">
                    <p>{subText}</p>
                  </div>
                )}
              </legend>
            );
          }
        })()}
        {questionLarge && (
          <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
            <h1 className="govuk-fieldset__heading">{questionLarge}</h1>
            {subText && (
              <div id={hintId} className="govuk-hint">
                <p>{subText}</p>
              </div>
            )}
          </legend>
        )}
        {error && (
          <span id={`${name}.error`} className="govuk-error-message">
            <span className="govuk-visually-hidden">Error:</span>
            {error}
          </span>
        )}
        <div className={radioClassName}>
          {enumValues &&
            enumValues.map((value, key) => setRadioOptions(value, key))}
        </div>
      </fieldset>
    </div>
  );
};
