import React from 'react';

const UploadMessage = (props) => {
  switch (props.message) {
    case 'UPLOAD_COMPLETED_WITH_ISSUES':
      return (
        <>
          <div className="upload-message">
            Your file has uploaded correctly, thank you. We have spotted some
            opportunities to improve data quality for future reports; please
            contact the support team (
            <a href="mailto:chemicalreportingteam@homeoffice.gov.uk">
              chemicalreportingteam@homeoffice.gov.uk
            </a>
            ) if you would like to discuss.
          </div>
        </>
      );
    case 'UPLOAD_COMPLETED':
      return (
        <>
          <div className="upload-message">
            Your file has been uploaded and processed correctly, thank you.
          </div>
        </>
      );
    case 'UPLOAD_COMPLETED_NOT_FOLLOWED_SCHEMA':
      return (
        <>
          <div className="upload-message">
            Your file has uploaded correctly, thank you. Using our new reporting
            schema could simplify the process for future reports; please contact
            the support team (
            <a href="mailto:chemicalreportingteam@homeoffice.gov.uk">
              chemicalreportingteam@homeoffice.gov.uk
            </a>
            ) if you would like to discuss.
          </div>
        </>
      );
    case 'UPLOAD_FAILED':
      return (
        <>
          <div className="upload-message">
            Your report has failed to upload. Please contact{' '}
            <a href="mailto:chemicalreportingteam@homeoffice.gov.uk">
              chemicalreportingteam@homeoffice.gov.uk
            </a>{' '}
            to discuss.
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="upload-message">{props.message}</div>
        </>
      );
  }
};

export { UploadMessage };
