import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { DatePicker } from './DatePicker';

export const Date = ({
  question,
  name,
  headerSize,
  subText,
  questionLarge,
}) => {
  const formik = useFormikContext();
  const [error, setError] = useState();
  useEffect(() => {
    const dateError =
      get(formik.errors, `${name}.day`) ||
      get(formik.errors, `${name}.month`) ||
      get(formik.errors, `${name}.year`);
    setError(dateError);
  }, [formik.errors, get]);

  return (
    <div
      className={classNames('govuk-form-group', {
        'govuk-form-group--error': error,
      })}
    >
      <fieldset
        className="govuk-fieldset"
        role="group"
        aria-describedby={`${name}-hint`}
      >
        {question && (
          <legend
            className={
              headerSize === 's'
                ? 'govuk-fieldset__legend'
                : 'govuk-fieldset__legend govuk-fieldset__legend--m'
            }
            id={`label-${name}`}
          >
            <h2 className="govuk-fieldset__heading">{question}</h2>
          </legend>
        )}
        {questionLarge && (
          <legend
            className="govuk-fieldset__legend govuk-fieldset__legend--l"
            id={`label-${name}`}
          >
            <h1 className="govuk-fieldset__heading">{questionLarge}</h1>
          </legend>
        )}
        {subText && (
          <div id={`${name}-hint`} className="govuk-hint">
            {subText}
          </div>
        )}
        {error && (
          <span id={`${name}.error`} className="govuk-error-message">
            <span className="govuk-visually-hidden">Error:</span>
            {error}
          </span>
        )}
        <div className="govuk-date-input" id={`${name}`}>
          <DatePicker name={name} />
        </div>
      </fieldset>
    </div>
  );
};
