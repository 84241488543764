import React from 'react';
import { dateFormat } from '../../../../DateFormat';

export const PreviousUploadsTableRow = ({ report, onReportDetailClick }) => {
  return (
    <>
      <tr
        key={report.source_id}
        className="govuk-table__row"
        data-test={`table-row-${report.source_id}`}
      >
        <td
          className="govuk-table__cell"
          data-test={`table-cell-timestamp-${report.source_id}`}
        >
          {report.uploaded_timestamp
            ? dateFormat.format(new Date(report.uploaded_timestamp))
            : ''}
        </td>
        <td
          className="govuk-table__cell"
          data-test={`table-cell-retailer-${report.source_id}`}
        >
          {report.retailer}
        </td>
        <td
          className="govuk-table__cell"
          data-test={`table-cell-filename-${report.source_id}`}
        >
          <a
            data-test={`table-cell-more-${report.source_id}`}
            href=""
            onClick={(e) => {
              e.preventDefault();
              onReportDetailClick(report);
            }}
          >
            {report.source_name}
          </a>
        </td>
        <td
          className="govuk-table__cell"
          data-test={`table-cell-source-id-${report.source_id}`}
        >
          {report.source_id}
        </td>
        <td
          className="govuk-table__cell"
          data-test={`table-cell-status-${report.source_id}`}
        >
          {report.status}
        </td>
      </tr>
    </>
  );
};
