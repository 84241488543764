import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import get from 'lodash/get';

export const InputBox = ({ label, name, subText, hintId }) => {
  const formik = useFormikContext();
  const [error, setError] = useState();

  useEffect(() => {
    setError(get(formik.errors, name));
  }, [formik.errors, get, name]);

  return (
    <>
      <div
        className={classNames('govuk-form-group', {
          'govuk-form-group--error': error,
        })}
      >
        <label className="govuk-label" htmlFor={`${name}`} id={`label-${name}`}>
          {label}
        </label>
        {error && (
          <span id={`${name}.error`} className="govuk-error-message">
            <span className="govuk-visually-hidden">Error:</span>
            {error}
          </span>
        )}
        {subText && (
          <div id={hintId} className="govuk-hint">
            <p>{subText}</p>
          </div>
        )}
        <Field
          className={classNames('govuk-input', {
            'govuk-input--error': error,
          })}
          id={name}
          name={name}
          data-test={name}
          aria-describedby={`label-${name}`}
        />
      </div>
    </>
  );
};
