import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Field, useFormikContext } from 'formik';

export const DatePicker = ({ name }) => {
  const formik = useFormikContext();
  const [error, setError] = useState();
  useEffect(() => {
    const dateError =
      get(formik.errors, `${name}.day`) ||
      get(formik.errors, `${name}.month`) ||
      get(formik.errors, `${name}.year`);
    setError(dateError);
  }, [formik.errors, get]);
  const inputClasses = ['govuk-input', 'govuk-date-input__input'];

  return (
    <>
      <div className="govuk-date-input__item">
        <div className="govuk-form-group">
          <label className="govuk-label govuk-date-input__label">Day</label>
          <Field
            className={classNames(inputClasses, 'govuk-input--width-2', {
              'govuk-input--error': error,
            })}
            id={`${name}.day`}
            name={`${name}.day`}
            data-test={`${name}.day`}
            aria-label="Day"
            maxLength={2}
          />
        </div>
      </div>
      <div className="govuk-date-input__item">
        <div className="govuk-form-group">
          <label className="govuk-label govuk-date-input__label">Month</label>
          <Field
            className={classNames(inputClasses, 'govuk-input--width-2', {
              'govuk-input--error': error,
            })}
            id={`${name}.month`}
            name={`${name}.month`}
            data-test={`${name}.month`}
            aria-label="Month"
            maxLength={2}
          />
        </div>
      </div>

      <div className="govuk-date-input__item">
        <div className="govuk-form-group">
          <label className="govuk-label govuk-date-input__label">Year</label>
          <Field
            className={classNames(inputClasses, 'govuk-input--width-4', {
              'govuk-input--error': error,
            })}
            id={`${name}.year`}
            name={`${name}.year`}
            data-test={`${name}.year`}
            aria-label="Year"
            maxLength={4}
          />
        </div>
      </div>
    </>
  );
};
