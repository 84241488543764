import React from 'react';

const OneOffSarsError = () => {
  return (
    <main
      className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
      id="content"
      role="main"
      tabIndex={0}
    >
      <h1 data-test="error-header">
        Sorry, there is a problem with the service
      </h1>
      <p data-test="error-text-1">
        Try again later or make a report to the Anti-Terrorist Hotline on{' '}
        <a href="tel:+0800789321">0800 789 321</a>.
      </p>
      <p data-test="error-text-2">
        Contact the{' '}
        <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
          Chemical Reporting Team
        </a>{' '}
        if you require further support. The inbox is monitored by the team
        Monday to Friday 9am to 5pm. It is not monitored on bank holidays.
      </p>
    </main>
  );
};
export default OneOffSarsError;
