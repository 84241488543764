// https://design-system.service.gov.uk/styles/colour/
export const GovUkColors = {
  red: '#d4351c',
  yellow: '#ffdd00',
  green: '#00703c',
  blue: '#1d70b8',
  darkBlue: '#003078',
  lightBlue: '#5694ca',
  purple: '#4c2c92',
  black: '#0b0c0c',
  darkGrey: '#6f777b',
  midGrey: '#b1b4b6',
  lightGrey: '#f3f2f1',
  white: '#ffffff',
  lightPurple: '#6f72af',
  brightPurple: '#912b88',
  pink: '#d53880',
  lightPink: '#f499be',
  orange: '#f47738',
  brown: '#b58840',
  lightGreen: '#85994b',
  turquoise: '#28a197',
  textPrimary: '#0b0c0c',
  textSecondary: '#6f777b',
  linkDefault: '#1d70b8',
  linkHover: '#003078',
  linkVisited: '#4c2c92',
  linkActive: '#0b0c0c',
  borderDefault: '#b1b4b6',
  borderInput: '#0b0c0c',
  focusDefault: '#ffdd00',
  focusText: '#0b0c0c',
  error: '#d4351c',
  brand: '#1d70b8',
};

const theme = {
  colors: GovUkColors,
};

export default theme;
