import React from 'react';

const Unauthorised = () => {
  return (
    <div>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <h1 data-test="error-header">
            Sorry, you are not permitted to access this service
          </h1>
          <p data-test="error-text-1">
            Please let the support team know you are unable to access the
            service.
          </p>
          <p data-test="error-text-2">
            Email address: <br></br>
            <a
              href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
              data-test="e-mail-link"
            >
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
          </p>
          <p data-test="error-text-3">
            For further information on chemical reporting please visit:{' '}
            <br></br>
            <a
              href="https://www.gov.uk/government/publications/supplying-explosives-precursors/supplying-explosives-precursors-and-poison"
              data-test="further-details-link"
            >
              https://www.gov.uk/government/publications/supplying-explosives-precursors/supplying-explosives-precursors-and-poison
            </a>
          </p>
        </div>
      </main>
    </div>
  );
};
export default Unauthorised;
