import React from 'react';

const GenericError = () => {
  return (
    <div>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <h1 data-test="error-header">Internal Server Error</h1>
          <p data-test="error-text-1">
            The service is currently experiencing an issue, please retry later.
          </p>
        </div>
      </main>
    </div>
  );
};
export default GenericError;
