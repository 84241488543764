import React, { createContext } from 'react';
import { getConfig } from '../../Config';
import axios from 'axios';

const portalPublicEndpoint = getConfig().portalPublicUrl;
const keycloakEndpoint = getConfig().keycloakUrl;
const keycloakClientID = getConfig().keycloakClientID;

export const OneOffAuthContext = createContext();

export const AuthConsumer = OneOffAuthContext.Consumer;

const OneOffAuthProvider = (props) => {
  const validateUser = (hash) => {
    try {
      if (hash) {
        const decodedHash = atob(hash.substring(1));
        const email = JSON.parse(decodedHash).username;
        setEmail(email);
      }
    } catch (err) {
      Promise.reject(err);
    }

    return axios
      .post(`${portalPublicEndpoint}/user/validate`, {
        session: hash.substring(1),
      })
      .then((res) => {
        setAccessToken(res.data.access_token);
        setIDToken(res.data.id_token);
        setRefreshToken(res.data.refresh_token);
        setTokenExp(
          new Date(
            JSON.parse(window.atob(res.data.access_token.split('.')[1])).exp *
              1000
          )
        );
        setAuthenticated(true);
      });
  };

  const setEmail = (email) => {
    return sessionStorage.setItem('email', email);
  };

  const getEmail = () => {
    return sessionStorage.getItem('email');
  };

  const isOneOffAuthenticated = () => {
    return sessionStorage.getItem('authenticated') === 'true';
  };

  const setAuthenticated = (val) => {
    return sessionStorage.setItem('authenticated', val);
  };

  const getAccessToken = () => {
    return sessionStorage.getItem('accessToken');
  };

  const setAccessToken = (accessToken) => {
    return sessionStorage.setItem('accessToken', accessToken);
  };

  const getIDToken = () => {
    return sessionStorage.getItem('idToken');
  };

  const setIDToken = (idToken) => {
    return sessionStorage.setItem('idToken', idToken);
  };

  const setTokenExp = (tokenExp) => {
    return sessionStorage.setItem('tokenExp', tokenExp);
  };

  const getTokenExp = () => {
    return sessionStorage.getItem('tokenExp');
  };

  const setRefreshToken = (refreshToken) => {
    return sessionStorage.setItem('refreshToken', refreshToken);
  };

  const getRefreshToken = () => {
    return sessionStorage.getItem('refreshToken');
  };

  const clearTokens = () => {
    sessionStorage.clear();
  };

  const getValidToken = () => {
    if (new Date(getTokenExp()) > new Date()) {
      return Promise.resolve();
    } else {
      return refreshToken();
    }
  };

  const createFormUrlEncoded = (data) => {
    const requestBody = Object.entries(data)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join('&');

    return requestBody;
  };

  const refreshToken = () => {
    const data = {
      client_id: keycloakClientID,
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken(),
    };

    const requestBody = createFormUrlEncoded(data);

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: requestBody,
      url: keycloakEndpoint,
    };

    return axios(options).then((res) => {
      setAccessToken(res.data.access_token);
      setIDToken(res.data.id_token);
      setRefreshToken(res.data.refresh_token);
      setTokenExp(
        new Date(
          JSON.parse(window.atob(res.data.access_token.split('.')[1])).exp *
            1000
        )
      );
    });
  };

  return (
    <OneOffAuthContext.Provider
      value={{
        validateUser,
        isOneOffAuthenticated,
        getAccessToken,
        setAuthenticated,
        getValidToken,
        refreshToken,
        getIDToken,
        clearTokens,
        getEmail,
      }}
    >
      {props.children}
    </OneOffAuthContext.Provider>
  );
};

export default OneOffAuthProvider;
