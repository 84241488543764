import styled from 'styled-components';
import theme from '../../../Theme';

export const LoadingSpinner = styled.div`
  border: 9px solid ${theme.colors.lightBlue};
  border-radius: 70%;
  border-top-color: ${theme.colors.darkBlue};
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  left: 50%;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

LoadingSpinner.displayName = 'LoadingSpinner';
